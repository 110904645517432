<template>
  <div >
    <div style="padding: 0 80px;">
      <div class="page-header clear-filter" style="border-radius: 40px">
        <parallax
          class="page-header-image"
          style="background-image: url('/assets/image/bg/bg_19.png')"
        >
        </parallax>
        <div class="container px-0">
          <div class="title-content">
            <h1 class="font-weight-bold" style="font-size: 64px">
              {{ $t('smartbus-feature') }}
            </h1>
            <h3 class="mb-4 mx-auto" style="font-size: 24px">
              {{ $t('smartbus-feature-content') }}
            </h3>

            <span>{{ $t('try-now') }}</span>
            <div class="mt-4">
              <a href="https://apps.apple.com/vn/app/nlt-smartbus/id6453477112" class="btn-down btn btn-lg" role="button">
                <i class="fab fa-apple mr-2"></i>
                App Store
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.nltbus&hl=vi&gl=US&pli=1"
                target="_blank"
                class="btn btn-lg btn-simple btn-round m-2 btn-down"
                role="button"
              >
                <i class="fab fa-google-play mr-2"></i>
                Google Play
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Intro class="mt-5 fixed-width"></Intro>
    <div style="margin: 40px auto; max-width: 1440px"></div>
    <Feature></Feature>
    <Feedback  class="space-between"></Feedback>
    <DowloadFooter ></DowloadFooter>
  </div>
  
</template>
<script>
import Intro from "./components/IntroForm.vue";
import Feedback from "./components/Feedback.vue";
import DowloadFooter from "./components/FooterDownload.vue";
import Feature from "./components/FeatureForm.vue";
export default {
  name: "feature",
  bodyClass: "feature-page",
  components: {
    Feedback,
    DowloadFooter,
    Feature,
    Intro
  },
  created() {
    localStorage.setItem("currentUrl", "HOME");
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(value) {
      let reveals = document.querySelectorAll(".reveal");
      let height = window.innerHeight;
      //console.log(reveals[0].getBoundingClientRect().top);
      for (let i = 0; i < reveals.length; i++) {
        let windowHeight = window.innerHeight;
        let elementTop = reveals[i].getBoundingClientRect().top;
        let elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    },
  },
};
</script>
<style scoped>
.btn-down {
  border: 1px solid white !important;
  border-radius: 30px;
  padding: 15px 40px;
  color: white !important;
  transition: 500ms;
  background-color: transparent !important;
  width: 218px;
}
.btn-down:hover {
  background-color: #c71313 !important;
  border: 1px solid #c71313 !important;
}
.title-content {
  margin: 9rem 0 0 0;
  text-align: center;
}
.desrcep {
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 40px 64px -32px rgba(0, 0, 0, 0.1);
  margin-top: -20rem;
  padding: 3rem 1rem 3rem 1rem;
}
.space-between {
  margin-bottom: 120px !important;
}
.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}
.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
.fixed-width {
  max-width: 1440px;
  margin: 0 auto;
}
</style>
