<template>
  <footer class="" :data-background-color="backgroundColor">
    <div class="" style="padding: 80px 80px; background-color: #18191a;">
      <div class="container-fluid">
        <div class="row" style="max-width: 1440px; margin: 0 auto">
          <div class="col-lg-4 p-0">
            <div class="d-flex">
            <div class="text-center">
              <img src="@/assets/image/logo/App-icon-white.svg" height="73px"/>
              <h2 class="h2" style="font-weight: 700;font-size: 42px;"> SmartBus</h2>
            </div>
          </div>
          </div>
          <div class="col-lg-6">
            <h3 class="company-name">{{ $t('company-name') }}</h3>
            <p class="contact">{{ $t('hq-addr') }}</p>
            <p class="contact">Hotline:&nbsp;(+84)0911379581</p>
            <p class="contact">Email:&nbsp;kinhdoanh@nlt-group.com</p>
            <p class="contact">Mã số doanh nghiệp:&nbsp;0313339640</p>
          </div>
          <div class="col-lg-2" style="font-size: 14px;">
            <p class="text-uppercase">
              <a href="/" @click="pushUrl('HOME')" class="nav-hover fs-24">{{ $t('home') }}</a>
            </p>
            <p class="text-uppercase">
              <a href="/map" @click="pushUrl('MAP')" class="nav-hover fs-24">{{ $t('web-version') }}</a>
            </p>
            <p class="text-uppercase">
              <a href="/introduce" @click="pushUrl('INTRODUCE')" class="nav-hover fs-24">{{ $t('user-guideline') }}</a>
            </p>
            <p class="text-uppercase ">
              <a href="/feature" @click="pushUrl('FEATURE')" class="nav-hover fs-24">{{ $t('feature') }}</a>
            </p>
            <p class="text-uppercase">
              <a href="/contact" @click="pushUrl('CONTACT')" class="nav-hover fs-24">{{ $t('contact') }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-center py-2"
      style="background-color: #c71313"
    >
      <div class="copyright">
        &copy; {{ year }} Copyright by
        <a href="https://nlt-group.com/" target="_blank" rel="noopener"
          >Nam Long Technology Investment Group (NLT-Group.com)</a
        >
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  methods: {
    pushUrl(url) {
      localStorage.setItem("currentUrl", url);
    },
  },
};
</script>
<style scoped>
.fs-24{
  font-size: 14px;
}
.contact{
  font-size: 14px;
}
.nav-hover:hover {
  /* transition: width 0.3s ease; */
  color: #fcfcfc !important ;
  /* border-bottom: 2px solid #c71313; */
}
@keyframes underline {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

.nav-hover {
  position: relative;
  display: inline-block;
  color: inherit;
  text-decoration: none;
}

.nav-hover::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0%;
  height: 2px;
  background-color: #fcfcfc;
  color: #fcfcfc !important ;
  transform-origin: left center;
  transition: width 0.3s;
}

.nav-hover:hover::after {
  width: 100%;
  animation: underline 300ms linear forwards;
}

.nav-hover:not(:hover)::after {
  width: 0%;
}
.company-name{
  font-size: 24px;
}
</style>
