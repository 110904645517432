/** Load region default **/
export const listURL = {
    DEV: {
        id: 1,
        urlAPI: "https://apidev.namlongtekgroup.com",
        urlImage: "https://imgsmartcity.namlongtekgroup.com/", 
    },
    DEPLOY: {
        id: 2,
        urlAPI: "https://apidev.namlongtekgroup.com",
        urlImage: "https://imgsmartcity.namlongtekgroup.com/", 
    },
    DienLucHCM: {
        id: 3,
        urlAPI: "https://apidienluc.quantracthongminh.com",
        urlImage: "https://imgsmartcity.namlongtekgroup.com/",
    },
    PMG: {
        id: 4,
        urlAPI: "https://apisystem.gasthoidai.com",
        urlImage: "https://img.gasthoidai.com/",
    },
    VGas247: {
        id: 5,
        urlAPI: "https://apiquanly.vgas247.com",
        urlImage: "https://file.vgas247.com/",
    },
    LongAn: {
        id: 6,
        urlAPI: "https://apilongan.quantracthongminh.com",
        urlImage: "https://imgsmartcity.namlongtekgroup.com/",
    },
    HauGiang: {
        id: 7,
        urlAPI: "https://apihaugiang.quantracthongminh.com",
        urlImage: "https://imgsmartcity.namlongtekgroup.com/",
    },
    CanTho: {
        id: 8,
        urlAPI: "https://cstm-api.vnptcantho.com.vn",
        urlImage: "https://cstm-file.vnptcantho.com.vn/",
    },
    PTHDaNang: {
        id: 9,
        urlAPI: "https://apipthdn.nlt-group.com",
        urlImage: "https://imgsmartcity.namlongtekgroup.com/",
    },
    PTHNhaTrang: {
        id: 10,
        urlAPI: "https://dhnhatrangapi.nlt-group.com",
        urlImage: "https://imgsmartcity.namlongtekgroup.com/",
    },
    PTHNinhThuan: {
        id: 11,
        urlAPI: "https://ninhthuanapi.nlt-group.com",
        urlImage: "https://imgsmartcity.namlongtekgroup.com/",
    },
    PhanRang: {
        id: 12,
        urlAPI: "https://apiphanrang.nlt-group.com",
        urlImage: "https://imgsmartcity.namlongtekgroup.com/",
    },
    MobileDEV: {
        id: 13,
        urlAPI: "https://mobiledev2.namlongtekgroup.com",
        urlImage: "https://imgsmartcity.namlongtekgroup.com/", 
    },
}
