<template>
  <div style="position: relative">
    <div class="input-group mb-3">
      <b-dropdown class="dropdown-city" toggle-class="toggle-btn py-0">
        <template #button-content>
          <img src="/assets/image/icon/cc.svg" />
          <span>{{ currentServer }}</span>
        </template>
        <b-dropdown-item
          v-for="(e, index) in listSever"
          :key="index"
          href="#"
          @click="handleChoseServe(e)"
          >{{ e.Name }}</b-dropdown-item
        >
      </b-dropdown>
      <div class="input-group-prepend" style="height: 40px">
        <span class="input-group-text py-0">
          <i class="fas fa-search"></i>
        </span>
      </div>
      <input
        id="search-station"
        type="search"
        class="form-control bg-white form-search"
        style="width: 436px"
        placeholder="Nhập điểm đến"
        v-model="searchText"
        @input="handleSearch"
        @blur="handleFocus(false)"
        @focus="handleFocus(true)"
      />
    </div>
    <div v-if="searchText && onBlur" class="search-form">
      <div
        v-for="(item, index) in listStationFilter"
        :key="index"
        class="search-item"
        @click="handleChooseStation(item)"
      >
        {{ item.BusStopName }}
      </div>
    </div>
  </div>
</template>

<script>
import RepositoryFactory from "@/repository/factory";
const authenticationGuest = RepositoryFactory.get("authenticationGuest");

export default {
  props: {
    listStation: {
      type: Array,
    },
  },
  data() {
    return {
      isSearching: false,
      searchText: "",
      listStationFilter: [],
      onBlur: false,
      clickOnList: false,
      listSever: [],
      currentServer: localStorage.getItem("serverName") && localStorage.getItem("serverName") !== 'DEV'
        ? localStorage.getItem("serverName")
        : this.handleChoseServe({
            Name: "Ninh Thuận",
            ServerName: "DEV",
          }),
    };
  },
  created() {
    this.ListServerName();
  },
  methods: {
    handleChoseServe(serve) {
      localStorage.setItem("server", serve.ServerName);
      localStorage.setItem("serverName", serve.Name);
      this.currentServer = serve.Name;
      location.reload()
    },
    async ListServerName() {
      let res = await authenticationGuest.ListServerName();
      this.listSever = res.status ? res.data : [];
    },
    handleFocus(value) {
      setTimeout(() => {
        if (this.clickOnList) {
          this.clickOnList = false;
          return;
        }
        this.onBlur = value;
      }, 200);
    },
    handleSearch() {
      let arr = [...this.listStation];
      let filter = arr.filter((e) =>
        e.BusStopName.toUpperCase().includes(this.searchText.toUpperCase())
      );
      this.listStationFilter = filter;
    },
    handleChooseStation(value) {
      this.onBlur = true;
      this.clickOnList = true;
      let input = document.getElementById("search-station");
      input.focus();
      this.$emit("rowclick", value);
    },
  },
};
</script>

<style>
.dropdown-city button {
  margin: 0 !important;
  background: var(--website-brand-color, #c71313);
}
.dropdown-city .btn:hover {
  background-color: #c71313 !important;
}
.dropdown-city .btn-secondary .dropdown-toggle:focus {
  background-color: #c71313 !important;
}
.toggle-btn {
  height: 40px;
  border-radius: 7px 0px 0px 7px !important;
}
</style>
<style scoped>
.search-item {
  cursor: pointer;
  padding: 6px 12px;
  border-collapse: separate;
}
.search-item:hover {
  background-color: lightgray;
}
.search-form {
  position: absolute;
  top: 40px;
  right: 0;
  width: calc(436px + 38px);
  max-height: 180px;
  background-color: whitesmoke;
  overflow: auto;
}
.form-search:focus {
  background-color: #c71313;
}
</style>
