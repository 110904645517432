<template>
  <div>
    <b-modal
      v-model="showModal"
      id="create-modal"
      content-class="modal-radius"
      centered
      hide-footer
      scrollable
      no-fade
    >
      <template #modal-header="{ close }">
        <div class="d-flex align-items-center w-100">
          <p class="modal-title">Đăng ký</p>
          <img
            src="/assets/image/icon/x-close.svg"
            class="ml-auto"
            @click="close"
            style="cursor: pointer"
          />
        </div>
      </template>
      <b-form @submit="onSubmit">
        <b-form-group
          class="mb-3"
          id="fieldset-1"
          label="Tên"
          label-class="lable-class"
          invalid-feedback="Không được bỏ trống tên"
          :state="state"
        >
          <b-form-input v-model="name" :state="state"></b-form-input>
        </b-form-group>
        <b-form-group
          class="mb-3"
          id="fieldset-1"
          label="Số điện thoại"
          label-class="lable-class"
          invalid-feedback="Không được bỏ trống sđt"
          :state="state"
        >
          <b-form-input v-model="phone" :state="state"></b-form-input>
        </b-form-group>
        <b-form-group
          class="mb-3"
          id="fieldset-1"
          label="Email"
          label-class="lable-class"
          invalid-feedback="Không được bỏ trống Email"
          :state="stateEmail"
        >
          <b-form-input v-model="email" :state="stateEmail"></b-form-input>
        </b-form-group>
        <b-form-group
          class="mb-3"
          id="fieldset-1"
          label="Mật khẩu"
          label-class="lable-class"
          invalid-feedback="Không được bỏ trống mật khẩu"
          :state="statePass"
        >
          <div class="input-group mb-3">
            <b-form-input
              :type="`${showPass ? 'text' : 'password'}`"
              v-model="pass"
              :state="statePass"
            ></b-form-input>
            <div class="input-group-append" style="height: 40px">
              <span
                class="input-group-text py-0"
                style="cursor: pointer"
                @click="showPass = !showPass"
              >
                <i v-if="showPass" class="fas fa-eye"></i>
                <i v-else class="fas fa-eye-slash"></i>
              </span>
            </div>
          </div>
        </b-form-group>
        <b-form-group
          class="mb-3"
          id="fieldset-1"
          label="Xác nhận mật khẩu"
          label-class="lable-class"
          invalid-feedback="Mật khẩu không trùng khớp"
          :state="statePassRe"
        >
          <div class="input-group mb-3">
            <b-form-input
              :type="`${showPassRe ? 'text' : 'password'}`"
              v-model="passRepeat"
              :state="statePassRe"
            ></b-form-input>
            <div class="input-group-append" style="height: 40px">
              <span
                class="input-group-text py-0"
                style="cursor: pointer"
                @click="showPassRe = !showPassRe"
              >
                <i v-if="showPassRe" class="fas fa-eye"></i>
                <i v-else class="fas fa-eye-slash"></i>
              </span>
            </div>
          </div>
        </b-form-group>
        <p v-if="error" class="m-0 text-danger" style="font-size: medium">
          {{ error }}
        </p>
        <b-button type="submit" class="w-100 btn-submit">Đăng ký</b-button>
        <p class="mention w-100 text-center mt-3">
          Bạn đã có tài khoản?
          <span class="attribute" @click="openModalLogin">Đăng nhập</span>
        </p>
      </b-form>
    </b-modal>
    <b-modal
      id="succes-created-modal"
      size="md"
      hide-footer
      hide-header
      centered
      scrollable
      content-class="border-radius-24"
    >
      <div class="text-center">
        <img src="/assets/image/icon/c-check 1.svg" class="mb-4" />
        <p class="succes-title mb-4">Thành công</p>
        <p class="mb-4" style="font-size: 16px">
          Tài khoản đã được đăng kí thành công, thông báo này sẽ được tự động
          đóng trong 3 giây
        </p>
        <b-button
          pill
          style="background-color: #c71313"
          class="px-5"
          @click="$bvModal.hide('succes-modal')"
          >ĐÃ HIỂU</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import RepositoryFactory from "@/repository/factory";
const authenticationGuest = RepositoryFactory.get("authenticationGuest");
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isShow(to) {
      if (to === false) {
        this.$emit("close");
        this.$bvModal.hide("create-modal");
      } else {
        this.$bvModal.show("create-modal");
      }
    },
    showModal(to) {
      if (to === false) {
        this.$emit("close");
        this.$bvModal.hide("create-modal");
      } else {
        this.$bvModal.show("create-modal");
      }
    },
  },
  data() {
    return {
      showModal: false,
      state: null,
      statePass: null,
      statePassRe: null,
      stateEmail: null,
      name: null,
      pass: null,
      passRepeat: null,
      phone: null,
      error: "",
      email: null,
      showPass: false,
      showPassRe: false,
    };
  },
  methods: {
    openModalLogin() {
      this.$emit("openLogin");
    },
    async onSubmit(event) {
      event.preventDefault();
      if (this.name && this.phone && this.pass && this.email) {
      } else {
        this.state = false;
        this.statePass = false;
        this.stateEmail = false;
        return;
      }
      if (this.pass !== this.passRepeat) {
        return (this.statePassRe = false);
      }

      let body = {
        FullName: this.name,
        Phone: this.phone,
        Email: this.email,
        Password: this.pass,
      };
      let res = await authenticationGuest.Register(body);
      if (res.status) {
        this.$bvModal.hide("create-modal");
        this.$bvModal.show("succes-created-modal");
        setTimeout(() => {
          this.$bvModal.hide("succes-created-modal");
          this.openModalLogin();
        }, 3000);
      } else {
        this.error = res.message;
      }
    },
  },
};
</script>
<style>
.modal-radius {
  border-radius: 8px !important;
}
</style>
<style scoped>
.succes-title {
  color: var(--website-neutral-60, #18191a);
  /* Website & tablet/Header 1 */
  font-size: 48px;
  font-weight: 400;
  line-height: 60px; /* 125% */
}
.mention {
  color: var(--label-main-text-default, #27272a);

  /* Base/14px - Regular */
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.attribute {
  color: var(--brand-color, #c71313);
  cursor: pointer;
  /* Small/12px - Regular */
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 18px */
}
.modal-title {
  color: var(--brand-color, #c71313);

  /* X-Large/20px - Semi Bold */
  font-size: 20px;
  font-weight: 600;
  line-height: 150%; /* 30px */
}
.btn-submit {
  border-radius: 8px;
  background: var(--brand-color, #c71313) !important;
}
</style>
