<template>
  <div>
    <div class="header-tab">
      <p class="back-btn" @click="action('TAB', 0)">
        <i class="fas fa-angle-left mr-2"></i>Trở về
      </p>
      <template v-if="dataByID">
        <p class="tab-title">Tuyến {{ dataByID.Code }}</p>
        <p class="route-name">{{ dataByID.Name }}</p>
        <div class="row mb-2">
          <div class="col-lg-5 p-0 text-center">
            {{ listBusStop[0].BusStopName }}
          </div>
          <div class="col-lg-2 p-0 text-center">
            <i class="fas fa-arrow-right"></i>
          </div>
          <div class="col-lg-5 p-0 text-center">
            {{ listBusStop[listBusStop.length - 1].BusStopName }}
          </div>
        </div>
        <b-button class="w-100 btn-changeroute m-0" @click="action('DIRECT')">
          <i class="fas fa-exchange-alt mr-2"></i>Đổi chiều tuyến
        </b-button>
      </template>
      <div class="route-card">
        <div class="d-flex justify-content-around text-center">
          <div
            class="cursor-pointer w-100 border-right"
            @click="currentTab = 0"
          >
            <div class="tab-btn" :class="{ 'is-active': currentTab === 0 }">
              Trạm dừng
            </div>
          </div>
          <div
            class="cursor-pointer w-100 border-right"
            @click="currentTab = 1"
          >
            <div class="tab-btn" :class="{ 'is-active': currentTab === 1 }">
              Thông tin
            </div>
          </div>
          <div
            class="cursor-pointer w-100"
            @click="currentTab = 2"
          >
            <div class="tab-btn" :class="{ 'is-active': currentTab === 2 }">
              Biểu đồ giờ
            </div>
          </div>
          <!-- <div class="cursor-pointer w-100" @click="currentTab = 3">
            <div class="tab-btn" :class="{ 'is-active': currentTab === 3 }">
              Đánh giá
            </div>
          </div> -->
        </div>
        <template v-if="dataByID">
          <b-tabs v-model="currentTab" nav-class="d-none" class="tab-height">
            <b-tab><List :items="listBusStop"></List></b-tab>
            <b-tab>
              <Info :items="dataByID" :listBusStop="listBusStop"> </Info>
            </b-tab>
            <b-tab><Table :fields="fieldTable" :items="timeLine"></Table></b-tab>
            <b-tab>
              <div class="text-center">
                <b-button
                  class="btn-changeroute py-2"
                  @click="$bvModal.show('feedback-modal')"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      id="Icon"
                      d="M7 8.5H12M7 12H15M7 18V20.3355C7 20.8684 7 21.1348 7.10923 21.2716C7.20422 21.3906 7.34827 21.4599 7.50054 21.4597C7.67563 21.4595 7.88367 21.2931 8.29976 20.9602L10.6852 19.0518C11.1725 18.662 11.4162 18.4671 11.6875 18.3285C11.9282 18.2055 12.1844 18.1156 12.4492 18.0613C12.7477 18 13.0597 18 13.6837 18H16.2C17.8802 18 18.7202 18 19.362 17.673C19.9265 17.3854 20.3854 16.9265 20.673 16.362C21 15.7202 21 14.8802 21 13.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V14C3 14.93 3 15.395 3.10222 15.7765C3.37962 16.8117 4.18827 17.6204 5.22354 17.8978C5.60504 18 6.07003 18 7 18Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Viết đánh giá
                </b-button>
              </div>
            </b-tab>
          </b-tabs>
        </template>
      </div>
      <b-modal
        id="feedback-modal"
        centered
        scrollable
        header-class="pt-3"
        content-class="modal-radius"
        hide-footer
        size="md"
        v-model="isShow"
      >
        <template #modal-header="{ close }">
          <div class="d-flex align-items-center w-100">
            <p v-if="dataByID" class="modal-title">Góp ý</p>
            <img
              src="/assets/image/icon/x-close.svg"
              class="ml-auto"
              @click="close"
              style="cursor: pointer"
            />
          </div>
        </template>
        <b-form @submit="onSubmit">
          <b-form-group
            id="fieldset-1"
            label="Nội dung góp ý"
            label-for="input-1"
            label-class="lable-class"
            :invalid-feedback="invalidFeedback"
            :state="state"
          >
            <b-form-select
              id="input-1"
              v-model="choseFeedback"
              :options="options"
              :state="state"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            label="Góp ý"
            label-for="input-1"
            label-class="lable-class"
            :invalid-feedback="invalidFeedback"
            :state="state"
          >
            <b-form-textarea
              id="input-1"
              rows="3"
              v-model="feedback"
              :state="state"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            label="Góp ý"
            label-for="input-1"
            label-class="lable-class"
          >
            <VueFileAgent
              :multiple="true"
              :deletable="true"
              :helpText="'Chọn hình ảnh'"
              @select="filesSelected($event)"
            ></VueFileAgent>
          </b-form-group>
          <b-button class="btn-submit" type="submit">Gửi</b-button>
        </b-form>
      </b-modal>
      <b-modal
        id="succes-modal"
        size="md"
        hide-footer
        hide-header
        centered
        scrollable
        content-class="border-radius-24"
      >
        <div class="text-center">
          <img src="/assets/image/icon/c-check 1.svg" class="mb-4" />
          <p class="succes-title mb-4">Cám ơn</p>
          <p class="mb-4" style="font-size: 16px">
            Chúng tôi đã nhận được tin nhắn của bạn. Nếu yêu cầu là khẩn cấp,
            vui lòng gọi đến số
            <strong class="font-weight-bold">(+84) 911 379 581</strong> để nói
            chuyện với một trong những nhân viên của chúng tôi. Nếu không, chúng
            tôi sẽ liên hệ với bạn qua email trong 2 ngày làm việc.
          </p>
          <b-button
            pill
            style="background-color: #c71313"
            class="px-5"
            @click="$bvModal.hide('succes-modal')"
            >ĐÃ HIỂU</b-button
          >
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import List from "@/components/List/ListRoute";
import Info from "./RouteInfor.vue";
import Table from "@/components/table/TimelineTable";

export default {
  components: {
    List,
    Info,
    Table,
  },
  props: {
    dataByID: {
      type: Object,
    },
    listBusStop: {
      type: Array,
    },
    timeLine: {
      type: Array
    }
  },
  computed: {
    invalidFeedback() {
      return "Vui lòng nhập đủ thông tin được yêu cầu ";
    },
    fieldTable() {
      if (!this.listBusStop) return;
      return [
        { key: "STT", label: "Chuyến số", class: "text-center" },
        {
          key: "ArrivalTime1",
          label: this.listBusStop[0].BusStopName,
          class: "text-center",
        },
        {
          key: "ArrivalTime2",
          label: this.listBusStop[this.listBusStop.length - 1].BusStopName,
          class: "text-center",
        },
      ];
    },
  },
  watch: {
    isShow(to) {
      if (to === false) {
        this.feedback = null;
        this.choseFeedback = null;
        this.state = null;
      }
    },
  },
  data() {
    return {
      isShow: false,
      currentTab: 0,
      feedback: "",
      choseFeedback: null,
      state: null,
      options: [
        { value: 1, text: "Tài xế" },
        { value: 2, text: "Thời gian đợi xe" },
        { value: 5, text: "Giá vé" },
        { value: 4, text: "Nhân viên" },
        { value: 3, text: "Khác" },
      ],
    };
  },
  methods: {
    filesSelected(value){
      console.log(value);
    },
    action(action, value) {
      this.$emit("action", action, value);
    },
    onSubmit(event) {
      event.preventDefault();
      if (this.feedback && this.choseFeedback) {
        this.state = true;
      } else {
        this.state = false;
      }
      if (!this.state) return;
      this.$bvModal.hide("feedback-modal");
      this.$bvModal.show("succes-modal");
    },
  },
};
</script>
<style>
.border-radius-24 {
  border-radius: 24px !important;
}
.lable-class {
  color: var(--label-main-text-default, #27272a);
  /* Base/14px - Bold */
  font-size: 14px !important;
  font-weight: 700;
  line-height: 150%; /* 21px */
}
.modal-radius {
  border-radius: 8px !important;
}
</style>
<style scoped>
.succes-title {
  color: var(--website-neutral-60, #18191a);
  /* Website & tablet/Header 1 */
  font-size: 48px;
  font-weight: 400;
  line-height: 60px; /* 125% */
}
.btn-submit {
  border-radius: 8px;
  background: var(--brand-color, #c71313) !important;
  width: 100%;
}

.modal-title {
  color: var(--brand-color, #c71313);

  /* X-Large/20px - Semi Bold */
  font-size: 20px;
  font-weight: 600;
  line-height: 150%; /* 30px */
}
.btn-feedback {
  background-color: white;
  color: #000;
  box-shadow: 0px 1px 4px 0px rgba(66, 71, 76, 0.12),
    0px 0px 0.5px 0px rgba(198, 204, 198, 0.32);
}
.btn-feedback:hover {
  background-color: #c71313;
  color: white;
  stroke: white !important;
}
.btn-feedback:focus {
  background-color: #c71313;
  color: white;
  stroke: white !important;
}
.btn-feedback:hover svg path {
  stroke: white !important;
}
.btn-feedback:focus svg path {
  stroke: white !important;
}
.tab-btn {
  padding: 3px;
  font-size: 12px;
}
.tab-height {
  height: calc(100vh - 270px - 163px);
  overflow-y: auto;
}
.tab-height::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute !important;
  transition: transform 0.3s;
}
/* .tab-height:hover {
  overflow-y: auto;
} */
.tab-height::-webkit-scrollbar-thumb {
  visibility: hidden;
}
.tab-height:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.is-active {
  border-radius: 6px;
  background: var(--brand-color, #c71313);
  color: #fff;
  margin: 0 5px;
}
.cursor-pointer {
  cursor: pointer;
  padding: 2px 0;
}
.route-card {
  padding-top: 8px;
  margin-top: 24px;
  border-radius: 8px;
  height: calc(100vh - 370px);
  /* App/Shadow 3 App */
  box-shadow: 0px 0px 0.5px 0px rgba(66, 71, 76, 0.32);
  background-color: #fff;
  /* overflow: auto; */
}
.btn-changeroute {
  border-radius: 8px;
  background: var(--system-background-primary, #fff);
  color: var(--website-brand-color, #c71313);
  /* App/Shadow 1 App */
  box-shadow: 0px 1px 4px 0px rgba(66, 71, 76, 0.12),
    0px 0px 0.5px 0px rgba(198, 204, 198, 0.32);
  stroke: #c71313 !important;
}
.btn-changeroute:hover {
  background: var(--system-background-primary, #c71313);
  color: var(--website-brand-color, #fff);
  stroke: #fff !important;
}
.btn-changeroute svg path {
  stroke: #c71313 !important;
}
.btn-changeroute:hover svg path {
  stroke: white !important;
}
.btn-changeroute:focus svg path {
  stroke: white !important;
}
.btn-changeroute:focus {
  background: var(--system-background-primary, #c71313);
}
.route-name {
  color: var(--label-color-primary, #000);
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4px;
  margin-bottom: 16px !important;
}
.back-btn {
  color: var(--website-brand-color, #c71313);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.4px;
  cursor: pointer;
}
.header-tab {
  padding: 24px 16px 0;
}
.text-color {
  color: var(--website-brand-color, #c71313);
}
.tab-title {
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: -0.4px;
}
</style>
