<template>
  <div>
    <div id="map" class="map">
      <!-- <div id="menu-left-click">
        <p>
          <strong>
            <span class="pl-4 float-right">
              <b-link
              @click.prevent.stop="
                copyCoordinate(coordinateClick.lat + ', ' + coordinateClick.lng)
              "
            >
              {{ $t("map.copy") }}
            </b-link>
            </span>
          </strong>
        </p>
        <p>
          <strong>
            <strong>
              <span class="pl-4 float-right">
                <b-link @click.prevent.stop="hidePopup">
                {{ $t("map.hide") }}
              </b-link>
              </span>
            </strong>
          </strong>
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import "leaflet-fullscreen/dist/Leaflet.fullscreen";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/leaflet.markercluster-src";
import {defaultRegionCode} from '@/constants/config.js'
import {listRegion} from '@/constants/region.js'

export default {
  components: {},
  props: {
    lines: {
      type: Array,
    },
    listStationOnLine: {
      type: Array,
    },
    listStation: {
      type: Array,
    },
    direction: {
      type: Boolean,
    },
    focus: {
      type: Object,
    },
    currentTab: {
      type: Number,
    },
  },
  data() {
    return {
      listRegion,
      map: null,
      intervalMAP: null,
      typeMap: "2D",
      regionCenter: "HoChiMinh",
      showNavMap: false,
      defaultRegionCode,
      line: null,
      marker: null,
    };
  },
  watch: {
    currentTab(to) {
      if (to === 0) {
        this.drawMarker(this.listStation);
        if (this.line) {
          this.map.removeLayer(this.line);
          this.line = null;
        }
      }
    },
    focus(to) {
      if (!to) return;
      this.flyPoint(to.Lat, to.Long);
      // this.drawMarker([to])
      if (this.line) {
        let _arr = [...this.listStationOnLine];
        let isOnLine = false;
        _arr.forEach((e) => {
          if (e.StopID === to.StopID || e.StopID === to.StationID) {
            isOnLine = true;
          }
        });
        if (!isOnLine) {
          _arr.push({ ...to });
          this.drawMarker(_arr);
        } else {
          this.drawMarker(_arr);
        }
        // this.drawMarker(_arr);
      } else {
        this.drawMarker([to]);
      }
    },
    lines(to, from) {
      if (this.map) {
        let routes = this.lines ? this.lines : null;
        this.flyPoint(routes[0][0], routes[0][1]);
        this.drawLine(routes);
        this.drawMarker(this.listStationOnLine);
      }
    },
  },
  mounted() {
    this.map = this.initMap();
    setTimeout(() => {
      this.map.invalidateSize();
      this.drawMarker(this.listStation);
      // let routes = this.lines ? this.lines : null;
      // this.drawLine(routes);
      // this.flyPoint(routes[0][0], routes[0][1]);
    }, 2000);
    this.intervalMAP = setInterval(() => {
      this.map.invalidateSize();
    }, 3000);
    // document
    //   .getElementById("btn-detail")
    //   .addEventListener("click", this.handleStationClick());
  },
  // beforeDestroy() {
  //   document
  //     .getElementById("btn-detail")
  //     .addEventListener("click", this.handleStationClick());
  // },
  methods: {
    initMap() {
      let mapDefault, urlMap;
      switch (this.typeMap) {
        case "GG":
          urlMap = "https://tile.openstreetmap.org/{z}/{x}/{y}.png";
          mapDefault = L.tileLayer(urlMap, {
            type: "MAP",
            minZoom: 1,
            maxZoom: 19,
            subdomains: ["mt0", "mt1", "mt2", "mt3"],
          });
          break;
        case "VT":
          urlMap = "https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}";
          mapDefault = L.tileLayer(urlMap, {
            type: "MAP",
            minZoom: 1,
            maxZoom: 21,
            subdomains: ["mt0", "mt1", "mt2", "mt3"],
          });
          break;
        case "2D":
          urlMap =
            "https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" +
            this.regionCenter +
            "&t=" +
            this.typeMap +
            "&z={z}&x={x}&y={y}&k=namlong_2021";
          mapDefault = L.tileLayer(urlMap, {
            type: "MAP",
            minZoom: 1,
            maxZoom: 18,
            subdomains: ["mt0", "mt1", "mt2", "mt3"],
          });
          break;
      }
      let map = L.map("map", {
        center: [this.listRegion[this.defaultRegionCode].lat,this.listRegion[this.defaultRegionCode].lng],
        zoom: 13,
        layers: mapDefault,
        // fullscreenControl: true,
        attributionControl: false,
        // maxZoom: 19, //này là chặn zoom trên bản đồ, phóng to quá bản đồ sẽ bị màu trắng
      });
      // map.addEventListener("click", this.openPoint);
      L.control.scale({ position: "bottomright" }).addTo(map);
      L.Control.zoomHome = L.Control.extend({
        options: {
          position: "bottomright",
          zoomHomeText: '<img src="/assets/image/icon/mark.svg" />',
          zoomHomeTitle: "Info",
          getLocation: this.getLocation()
        },

        onAdd: function (map) {
          var button = L.DomUtil.create("div", "location-btn" + " leaflet-bar"),
            options = this.options;
          // this._zoomHomeButton = this._createButton(
          //   options.zoomHomeText,
          //   options.zoomHomeTitle,
          //   "gin-control-zoom" + "-home",
          //   button,
          //   this._zoomHome
          // );
          button.innerHTML = options.zoomHomeText;
          // Thêm sự kiện click vào nút
          var btn = this.stateNav()
          L.DomEvent.on(button, "click", function () {
            this.getLocation
            // map.flyTo([parseFloat(lat), parseFloat(lng)], 15, {
            //   animate: true,
            //   duration: 2,
            // });
            
          });
          
          return button;
        },
        stateNav(state) {
         
        },
        
        _zoomHome: function (e) {
          e.preventDefault();
          this.stateNav();
        },
        _createButton: function (html, title, className, container, fn) {
          var link = L.DomUtil.create("a", className, container);
          link.innerHTML = html;
          link.href = "#";
          link.title = title;

          L.DomEvent.on(link, "mousedown dblclick", L.DomEvent.stopPropagation)
            .on(link, "click", L.DomEvent.stop)
            .on(link, "click", fn, this)
            .on(link, "click", this._refocusOnMap, this);

          return link;
        },
      });
      var zoomHome = new L.Control.zoomHome();
      // zoomHome.addTo(map);
      L.control
        .fullscreen({
          position: "topright", // Đặt vị trí của nút full screen ở góc trên bên phải
        })
        .addTo(map);
      map.zoomControl.setPosition("topright");
      map.invalidateSize();
      return map;
    },
    drawLine(_line) {
      if (this.line) {
        this.map.removeLayer(this.line);
      }
      if (_line) {
        this.line = new L.LayerGroup();
        // let latlngs = _line.coordinates.map((x) => [x[1], x[0]]);
        // let polyline = L.polyline(latlngs, { color: "red" });
        let polyline = new L.polyline([_line], {
          color: this.direction ? "red" : "#3B85FF",
          weight: 5,
        });
        this.line.addLayer(polyline);
        setTimeout(() => {
          if (this.map) {
            this.map.addLayer(this.line);
          }
        }, 200);
      }
    },
    drawMarker(location) {
      if (this.marker) {
        this.map.removeLayer(this.marker);
      }
      if (location?.length > 0) {
        this.marker = new L.markerClusterGroup({
          // type: "MARKER",
          // spiderfyOnMaxZoom: false,
          showCoverageOnHover: false,
          // // zoomToBoundsOnClick: false,
          // disableClusteringAtZoom: 18,
        });
        location.forEach((e, i) => {
          let temlatePopup = this.customHTML(e);
          let icon = new L.Icon({
            iconUrl: require(e.IsStation === 0
              ? "@/assets/image/icon/Bus-stop.svg"
              : "@/assets/image/icon/Bus-station.svg"),
            iconSize: [50, 40],
            iconAnchor: [20, 40],
            popupAnchor: [15, -30],
            className: "marker-svg-option-" + i,
          });
          let marker = L.marker(new L.LatLng(e.Lat, e.Long), { icon: icon });
          let popup = L.popup({
            closeButton: false,
            className: "custom-popup",
          }).setContent(temlatePopup);
          marker.bindPopup(popup);
          marker.on("mouseover", function (e) {
            // marker.openPopup();
          });
          marker.on("mouseout", function (e) {
            // marker.closePopup();
          });
          marker.on("click", () => {
            marker.closePopup();
            // setTimeout()
            marker.openPopup();
            document
              .getElementById("detail")
              .addEventListener("click", (value) => {
                this.handleStationClick(e);
                marker.closePopup();
              });
            //this.handleStationClick()
          });

          this.marker.addLayer(marker);
        });
        setTimeout(() => {
          if (this.map) {
            this.map.addLayer(this.marker);
          }
        }, 200);
      }
    },
    getLocation() {
    },
    customHTML(obj, moduleType) {
      let html = ``;
      html = `<div class="custom-popup">
              
              <div>
                <p class=" route-name border-bottom">
                  <strong>${obj.BusStopName}</strong>
                </p>
                <button id='detail' class="detail w-100">Xem chi tiết trạm</button>
              </div>
            </div>`;
      return html;
    },
    handleStationClick(value) {
      this.$emit("getBusStopDetail", value);
    },
    flyPoint(lat, lng) {
      this.map.flyTo([parseFloat(lat), parseFloat(lng)], 18, {
        animate: true,
        duration: 2,
      });
    },
  },
};
</script>
<style>
.location-btn {
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid var(--website-brand-color, #c71313) !important;
  /* App/Shadow 1 App */
  box-shadow: 0px 1px 4px 0px rgba(66, 71, 76, 0.12),
    0px 0px 0.5px 0px rgba(198, 204, 198, 0.32);
}
.custom-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
  margin: 10px;
}
.route-name {
  color: var(--website-brand-color, #c71313);
  font-size: 17px;
  letter-spacing: -0.4px;
  font-weight: 500;
  padding-bottom: 2px;
  margin: 0px 0px 10px 0px !important;
}
.detail {
  border-radius: 8px;
  border: 1px solid transparent;
  background: var(--system-background-primary, #c71313);
  color: var(--website-brand-color, #fff);
  font-size: 17px;
  /* App/Shadow 1 App */
  box-shadow: 0px 1px 4px 0px rgba(66, 71, 76, 0.12),
    0px 0px 0.5px 0px rgba(198, 204, 198, 0.32);
  padding: 8px;
}
.detail:hover {
  background: var(--system-background-primary, #a11616);
  color: var(--website-brand-color, #fff);
}
.detail:focus {
  background: var(--system-background-primary, #a11616);
  color: var(--website-brand-color, #fff);
}
</style>
<style scoped>
#map {
  height: calc(100vh - 58px);
  z-index: 0;
  background-color: white;
}
</style>
