<template>
  <div style="margin-bottom: 50px;max-width: 1440px;margin: 120px auto 120px;">
    <div class="container-fluid" style="padding: 0 70px;">
      <!-- <h3 class="title">VỀ CHÚNG TÔI</h3>
      <h5 class="description">
        Đơn vị hàng đầu cung cấp giải pháp thông minh đa ngành với khả năng thiết kế tùy chỉnh dựa vào yêu cầu khách
        hàng.
      </h5> -->
      <span class="text-uppercase title-block ">{{ $t('about-us') }}</span>
      <h2 class="content-slogan mt-4 mb-0">{{ $t('company-name1') }}</h2>
      <h2 class="content-slogan mt-0">{{ $t('company-name2') }}</h2>
      <div class="title-secondary col-lg-6 px-0">
        {{ $t('slogan') }}
      </div>
      <div class="row card-content">
        <div class="col-lg-6 col-md-12 p-4">
          
          <!-- <p class="mb-1">Mã số doanh nghiệp: <strong>0313339640</strong></p>
          <p class="mb-1">Hotline: <strong>0911 379 581</strong></p>
          <p class="mb-1">Email: <strong>kinhdoanh@nlt-group.com</strong></p> -->
          
            <p class="mb-1">{{ $t('transaction-hq') }}</p>
            <strong>{{ $t('trad-hq') }}</strong>
            <p class="mb-1 mt-3">{{ $t('headquarters') }}</p>
            <strong>{{ $t('hq-addr') }}</strong>
            <p class="mb-1 mt-3">{{ $t('hanoi') }} 
            </p>
            <strong>{{ $t('hanoi-addr') }}</strong>
            <p class="mb-1 mt-3">{{ $t('danang') }} 
            </p>
            <strong>{{ $t('danang-addr') }} </strong>
            <p class="mb-1 mt-3">{{ $t('nghean') }} </p>
            <strong>{{ $t('nghean-addr') }}</strong>
            <p class="mb-1 mt-3">{{ $t('ninhthuan') }} 
            </p>
            <strong>{{ $t('ninhthuan-addr') }}</strong>
            <p class="mb-1 mt-3">{{ $t('quangbinh') }} </p>
            <strong>{{ $t('quangbinh-addr') }}</strong>
            <p class="mb-1 mt-3">{{ $t('quangnam') }} </p>
            <strong>{{ $t('quangnam-addr') }}</strong>
            <p class="mb-1 mt-3">{{ $t('quangngai') }}</p>
            <strong>{{ $t('quangngai-addr') }}</strong>
            <p class="mb-1 mt-3">{{ $t('japan') }}</p>
            <strong>{{ $t('jp-addr') }}</strong>
         
        </div>
        <div class="col-lg-6 col-md-12 pr-0">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.104788139499!2d106.67347520108798!3d10.803285786867386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529cf7461442b%3A0xf5773ffa4f912f72!2zVOG6rXAgxJFvw6BuIMSQ4bqndSB0xrAgQ8O0bmcgbmdo4buHIE5hbSBMb25nIChOTFQgR3JvdXAp!5e0!3m2!1svi!2s!4v1684731653110!5m2!1svi!2s"
            style="border:0; height: 100%; width: 100%;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
</div></template>
<script>
export default {};
</script>
<style scoped>
.title-block {
  font-size: 16px;
  color: #636466;
  width: 50%;
}
.title-block {
  font-size: 12px;
  font-weight: 300;
  color: #636466;
  width: 75%;
}
.title-secondary {
  font-size: 16px;
  font-weight: 300;
  color: #636466;
  width: 75%;
  margin-bottom: 40px !important;
}
.content-slogan {
  color: #c71313;
  font-size: 48px !important;
  width: 50%;
  margin: 16px 0 !important;
}
.card-content{
  border: 1px solid lightgrey;
  border-radius: 24px;
  overflow: hidden;
  background: ;
}
</style>
