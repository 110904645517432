<template>
  <div class="custom-container-child">
    <div style="max-width: 1440px; margin: 0 auto">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-8 mt-5 pl-0">
            <h2>{{ $t("footer-title") }}</h2>
            <span>
              {{ $t("footer-content") }}
            </span>
            <div class="mt-4">
              <a
                href="https://apps.apple.com/vn/app/nlt-smartbus/id6453477112"
                class="btn btn-lg btn-simple btn-round btn-down ml-0 mr-3"
                target="_blank"
                role="button"
              >
                <i class="fab fa-apple mr-2"></i>
                App Store
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.nltbus&hl=vi&gl=US&pli=1"
                target="_blank"
                class="btn btn-lg btn-simple btn-round btn-down"
                role="button"
              >
                <i class="fab fa-google-play mr-2"></i>
                Google Play
              </a>
            </div>
          </div>
          <div class="col-lg-4">
            <img src="@/assets/image/mobile.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.btn-down {
  border: 1px solid grey !important;
  border-radius: 30px;
  padding: 15px 40px;
  color: grey !important;
  transition: 500ms;
  background-color: transparent !important;
  width: 218px;
}
.btn-down:hover {
  background-color: #c71313 !important;
  border: 1px solid #c71313 !important;
  color: white !important;
}
.custom-container-child {
  /* max-width: 328px; */
  padding: 40px 20px;
  background-color: #f0f0f0;
}
@media only screen and (min-width: 1024px) {
  .custom-container-child {
    padding: 40px 80px;
    background-color: #f0f0f0;
  }
}
@media only screen and (min-width: 1440px) {
  .custom-container-child {
    padding: 40px 80px;
    background-color: #f0f0f0;
  }
}
</style>
