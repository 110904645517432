<template>
  <div v-if="items" class="list">
    <div class="d-flex align-items-center mb-3">
      <div class="dot mr-3"></div>
      <strong class="name-info">Tuyến số: </strong>
      <p class="value-info">{{ items.Code }}</p>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div class="dot mr-3"></div>
      <strong class="name-info">Tên tuyến: </strong>
      <p class="value-info">{{ items.Name }}</p>
    </div>
    <!-- <div class="d-flex align-items-center mb-3">
      <div class="dot mr-3"></div>
      <strong class="name-info">Giá vé: </strong>
      <p class="value-info">api chưa có</p>
    </div> -->
    <div class="d-flex align-items-center mb-3">
      <div class="dot mr-3"></div>
      <strong class="name-info">Độ dài tuyến: </strong>
      <p class="value-info">{{ items.TotalLength }}</p>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div class="dot mr-3"></div>
      <strong class="name-info">Thời gian chạy: </strong>
      <p class="value-info">{{ items.WorkingHour }}</p>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div class="dot mr-3"></div>
      <strong class="name-info">Giãn cách tuyến: </strong>
      <p class="value-info">{{ items.NormalTimeWaiting }} - {{ items.PeakTimeWaiting }}</p>
    </div>
    <!-- <div class="d-flex align-items-center mb-3">
      <div class="dot mr-3"></div>
      <strong class="name-info">Đơn vị: </strong>
      <p class="value-info">api chưa có</p>
    </div> -->
    <!-- <div class="d-flex align-items-center mb-3" style="flex-wrap: wrap;">
      <div class="dot mr-3"></div>
      <strong class="name-info">Lượt đi: </strong>
      <span v-for="item,index in listBusStop" :key="index" class="value-info w-100">{{ item.BusStopName }}</span><br/>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Object,
    },
    listBusStop: {
        type: Array,
    }
  },
};
</script>

<style scoped>
.name-info {
  color: var(--label-color-primary, #000);
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.4px;
  margin-right: 3px;
}
.value-info {
  color: var(--label-color-primary, #000);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.4px;
  margin: 0;
}
.dot {
  border-radius: 50%;
  background: var(--website-brand-color, #c71313);
  width: 8px;
  height: 8px;
  z-index: 999;
}
.list {
  padding: 8px 16px;
}
</style>
