const routes = [
  {
    path: "/guideline/overview",
    component: () => import("./components/OverView.vue"),
  },
  {
    path: "/guideline/main",
    component: () => import("./components/Main.vue"),
  },
  {
    path: "/guideline/feature",
    component: () => import("./components/Feature.vue"),
  },
  {
    path: "/guideline/manual",
    component: () => import("./components/Manual.vue"),
  },
  {
    path: "/guideline/search-station",
    component: () => import("./components/Search-station.vue"),
  },
  {
    path: "/guideline/realtime-tracking",
    component: () => import("./components/RealtimeTracking.vue"),
  },
  {
    path: "/guideline/search-router",
    component: () => import("./components/SearchRouter.vue"),
  },
  {
    path: "/guideline/router-info",
    component: () => import("./components/RouterInfo.vue"),
  },
  {
    path: "/guideline/search-schedule",
    component: () => import("./components/SearchSchedule.vue"),
  },
  {
    path: "/guideline/rating",
    component: () => import("./components/Rating.vue"),
  },
];
export default routes;
