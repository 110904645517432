<template>
  <div class="d-flex">
    <SideBar
      class="side-bar"
      :class="{ 'is-visible': isHide }"
      @handleClick="isHide = !isHide"
      :isHide="isHide"
    >
      <b-tabs v-model="currentTab" nav-class="d-none" class="m-0">
        <b-tab class="tab-class">
          <div class="header-tab">
            <p class="text-color tab-title">{{ $t('search-router') }}</p>
            <div class="input-group mb-3">
              <div class="input-group-prepend" style="height: 40px">
                <span class="input-group-text py-0">
                  <i class="fas fa-search"></i
                ></span>
              </div>
              <input
                type="text"
                class="form-control bg-white form-search"
                :placeholder="$t('search-router-input')"
                v-model="search"
                @input="handleSearch"
              />
            </div>
          </div>
          <div class="content-tab">
            <div
              v-for="(item, index) in listRoutesFilter"
              :key="index"
              class="item-card"
            >
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-9">
                    <div class="row">
                      <div class="col-lg-2 p-0">
                        <img src="/assets/image/icon/bus.png" />
                      </div>
                      <div class="col-lg-10 p-0">
                        <strong class="router-name">
                          {{ item.BusRouteName }}
                        </strong>
                        <p class="m-0 router-info">{{ item.Note }}</p>
                        <p class="m-0 router-info">
                          <i class="far fa-clock"></i> 5:30 - 22:30
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="text-right col-lg-3 p-0">
                    <p class="m-0 detail-btn" @click="getRouteDetail(item.ID)">
                      {{ $t('detail-router') }}
                    </p>
                    <p class="m-0 router-info">
                      <br />
                    </p>
                    <!-- <p class="m-0 router-info">
                      <i class="far fa-money-bill-wave"></i> 7.000 VND
                    </p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab>
          <RouteDetail
            @action="actionChange"
            :dataByID="dataByID"
            :listBusStop="listBusStop"
            :timeLine="timeLine"
          ></RouteDetail>
        </b-tab>
        <b-tab>
          <StationDetail
            @action="actionChange"
            :stationDetail="stationDetail"
            :listRouteOfStation="listRouteOfStation"
            :listBusOfStation="listBusOfStation"
          ></StationDetail>
        </b-tab>
      </b-tabs>
    </SideBar>
    <Search
      class="search"
      @rowclick="getBusStopDetail"
      :class="{ 'is-visible-search': isHide }"
      :listStation="listStation"
    ></Search>
    <Map
      class="map"
      :focus="focus"
      :lines="lines"
      :listStationOnLine="listStationOnLine"
      :listStation="listStation"
      :direction="direction"
      :currentTab="currentTab"
      @getBusStopDetail="mapClick"
    ></Map>
  </div>
</template>

<script>
import Map from "./components-WebApp/Map.vue";
import SideBar from "../layout/SidebarMap.vue";
import Search from "./components-WebApp/Search.vue";
import RouteDetail from "./components-WebApp/RouteDetail.vue";
import StationDetail from "./components-WebApp/StationDetail.vue";
import RepositoryFactory from "@/repository/factory";
import { Switch } from "element-ui";
const cateBusRoutes = RepositoryFactory.get("cateBusRoutes");
const busGetMapNow = RepositoryFactory.get("busGetMapNow");
const busNoLogin = RepositoryFactory.get('busNoLogin')
export default {
  components: {
    Map,
    SideBar,
    Search,
    RouteDetail,
    StationDetail,
  },
  created() {
    // localStorage.setItem(
    //   "token",
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiNSIsIlNlcnZlck5hbWUiOiJERVYiLCJJc01hc3RlclVzZXIiOiIxIiwiR3JvdXBOYW1lIjoiR1VFU1QiLCJuYmYiOjE2OTIzNDEzMDQsImV4cCI6MTY5MjQyNzcwNCwiaWF0IjoxNjkyMzQxMzA0fQ.HkivCiNWhNMzWnr5bV-mfSptHb5lfDYEr0LEstiHkds"
    // );
    this.getListRoutes();
    this.getMapNow();
  },
  watch: {
    direction(to) {
      if (to === false) {
        this.dataByID.RouteDetails.forEach((e, i) => {
          if (e.Direction === 0) {
            this.lines = JSON.parse(e.StringPoint);
          }
        });
        this.listStationOnLine = this.dataByID.StopDetails.filter(
          (e) => e.Direction === 0
        );
        this.listBusStop = this.dataByID.StopDetails.filter(
          (e) => e.Direction === 0
        ).sort((a, b) => {
          return a.STT - b.STT;
        });
        this.timeLine = this.dataByID.BusSchedules.filter(
          (e) => e.direction === 0
        ).sort((a, b) => {
          return a.STT - b.STT;
        });
      } else {
        this.dataByID.RouteDetails.forEach((e, i) => {
          if (e.Direction === 1) {
            this.lines = JSON.parse(e.StringPoint);
          }
        });
        this.listStationOnLine = this.dataByID.StopDetails.filter(
          (e) => e.Direction === 1
        );
        this.listBusStop = this.dataByID.StopDetails.filter(
          (e) => e.Direction === 1
        ).sort((a, b) => {
          return a.STT - b.STT;
        });
        this.timeLine = this.dataByID.BusSchedules.filter(
          (e) => e.direction === 1
        ).sort((a, b) => {
          return a.STT - b.STT;
        });
      }
    },
  },
  data() {
    return {
      isHide: false,
      listRoutes: [],
      listRoutesFilter: [],
      isHideScroll: true,
      currentTab: 0,
      dataByID: null,
      search: "",
      direction: false,
      listBusStop: [],
      directionName: "",
      listStation: [],
      stationDetail: null,
      listRouteOfStation: [],
      listBusOfStation: [],
      lines: [],
      listStationOnLine: [],
      focus: null,
      ServerName: localStorage.getItem('server') ? localStorage.getItem('server') : 'DEV',
      timeLine: []
    };
  },
  methods: {
    mapClick(value) {
      value.ID = value.ID;
      this.getBusStopDetail(value);
    },
    async getBusStopDetail(value) {
      let body = {
        ServerName: this.ServerName,
        StopID: value.StopID ? value.StopID :  value.StationID,
      };
      let res = await busNoLogin.GetBusStopDetail(body);
      this.stationDetail = value;
      this.listRouteOfStation = res.status ? res.data[0] : null;
      this.listBusOfStation = res.status ? res.data[1] : null;
      this.focus = value
      this.mergeListBusAndStation(
        res.status ? res.data[0] : null,
        res.status ? res.data[1] : null
      );
      this.currentTab = 2;
    },
    mergeListBusAndStation(value, value2) {
      this.listRouteOfStation.forEach((item, index) => {
        let arr = [];
        this.listBusOfStation.forEach((e, i) => {
          if (item.RouteID === e.RouteID) {
            arr.push(e);
          }
        });
        item.listBus = arr.sort((a, b) => {
          return a.WaitingTime - b.WaitingTime;
        });
      });
    },
    async getMapNow() {
      let res = await busNoLogin.GetMapNow({ServerName: this.ServerName});
      this.listStation = res.status ? res.data : [];
    },
    handleSearch() {
      let arr = [...this.listRoutes];
      let filter = arr.filter((e) =>
        e.BusRouteName.toUpperCase().includes(this.search.toUpperCase())
      );
      this.listRoutesFilter = filter;
    },
    async getListRoutes() {
      let res = await busNoLogin.GetBusRoute({ServerName: this.ServerName});
      this.listRoutes = res.status ? res.data : [];
      this.listRoutesFilter = res.status ? res.data : [];
    },
    mouseOver(value) {
      this.isHideScroll = value;
    },
    async getRouteDetail(value) {
      let body = {
        ServerName: this.ServerName,
        RouteID: value
      }
      let res = await busNoLogin.GetBusRouteDetail(body);
      this.dataByID = res.status ? res.data : null;
      this.directionName = this.dataByID.Name;
      this.lines = JSON.parse(res.data.RouteDetails[0].StringPoint);
      this.listBusStop = this.dataByID.StopDetails.filter(
        (e) => e.Direction === 0
      ).sort((a, b) => {
        return a.STT - b.STT;
      });
      this.listStationOnLine = this.dataByID.StopDetails.filter(
        (e) => e.Direction === 0
      );
      this.timeLine = this.dataByID.BusSchedules.filter(
        (e) => e.direction === 0
      ).sort((a, b) => {
        return a.STT - b.STT;
      });
      this.currentTab = 1;
    },
    actionChange(action, value) {
      switch (action) {
        case "TAB":
          this.currentTab = value;
          break;
        case "DIRECT":
          this.direction = !this.direction;
          break;
      }
    },
  },
};
</script>
<style scoped>
.header-tab {
  padding: 24px 16px 0;
}
.content-tab {
  /* 58px là thanh nav */
  height: calc(100vh - 58px - 138px);
  overflow: auto !important;
  scrollbar-width: 20px !important;
  padding: 0 11px;
}
/* .content-tab:hover {
  height: calc(100vh - 58px - 121px);
  overflow: scroll !important;
  padding-right: 11px;
} */
.content-tab::-webkit-scrollbar {
  width: 5px;
  position: absolute !important;
  transition: transform 0.3s;
}
.content-tab::-webkit-scrollbar-thumb {
  visibility: hidden;
}
.content-tab:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.detail-btn {
  color: var(--website-brand-color, #c71313);
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4px;
  cursor: pointer;
}
.router-name {
  color: var(--label-color-primary, #000);
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.4px;
}
.router-info {
  color: var(--label-color-primary, #000);
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.4px;
}
.item-card {
  border-radius: 8px;
  border: 1px solid var(--bottom-bar-border-inactive, #ebebf0);
  background: var(--system-background-primary, #fff);
  padding: 16px;
  margin-bottom: 16px;
  transition: 0.5s;
}
.item-card:hover {
  border-radius: 8px;
  border: 1px solid var(--bottom-bar-border-inactive, #ebebf0);
  background: var(--system-background-primary, #fff);

  /* elevation-2 */
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
}
.tab-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: -0.4px;
}
.tabs {
  margin: 24px 16px;
}
.text-color {
  color: var(--website-brand-color, #c71313);
}
.search {
  position: absolute;
  top: calc(58px + 24px) !important;
  left: 24px;
  z-index: 2;
  transition: transform 0.3s ease-in-out;
  transform: translateX(396px);
}
.map {
  width: 100vw;
  position: absolute;
  top: 58px;
}
.side-bar {
  position: absolute;
  z-index: 1;
  top: 58px;
  left: 0;
  transition: transform 0.3s ease-in-out;
}
.is-visible {
  transform: translateX(-396px);
}
.is-visible-search {
  transform: translateX(0px);
}
</style>
