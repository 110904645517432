<template>
  <div class="totop" v-show="showButton" @click="scrollToTop">
    <!-- <div class="scrollToTopButton">
      <i class="fas fa-chevron-up"></i>
    </div> -->
    <svg
      class="scrollToTopButton"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#C71313" />
      <path
        d="M26 22L20 16L14 22"
        stroke="#FAFAFA"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      class="svgToTop"
      width="50"
      height="50"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        style="
          transition: stroke-dashoffset 10ms linear 0s;
          stroke-dasharray: 307.919, 307.919;
        "
        :style="`stroke-dashoffset: ${scrollValue};`"
        cx="24"
        cy="24"
        r="23"
        transform="rotate(-90 24 24)"
        stroke="#C71313"
        stroke-width="2"
      />
    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showButton: false,
      scrollValue: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.showButton = window.scrollY > 1; // Show the button after scrolling 300px
      this.scrollValue = this.convertRange(
        window.scrollY,
        0,
        document.body.scrollHeight - window.innerHeight,
        308,
        164
      );
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Smooth scrolling
      });
    },
    convertRange(value, fromMin, fromMax, toMin, toMax) {
      // Đảm bảo giá trị nằm trong phạm vi từ fromMin đến fromMax
      value = Math.min(Math.max(value, fromMin), fromMax);
      // Tính tỷ lệ tương ứng trong phạm vi từ 0 đến 1
      const normalizedValue = (value - fromMin) / (fromMax - fromMin);
      // Chuyển đổi sang phạm vi mới từ toMin đến toMax
      const convertedValue = normalizedValue * (toMax - toMin) + toMin;
      return convertedValue;
    },
  },
};
</script>

<style scoped>
.totop {
  position: fixed;
  bottom: 60px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.svgToTop {

}
.scrollToTopButton {
  background-color: #c71313;
  color: white;
  border: none;
  height: 43px;
  width: 43px;
  border-radius: 50%;
  transform: translateX(108%) translateY(0%);
}
</style>
