<template>
  <div>
    <div class="custom-container">
      <div
        class="page-header clear-filter"
        style="border-radius: 40px; height: 864px; color: #fcfcfc"
      >
        <parallax
          class="page-header-image"
          style="background-image: url('/assets/image/bg/bg_19.png')"
        >
        </parallax>
        <div class="container px-0">
          <div class="title-content">
            <h1 class="font-weight-bold" style="font-size: 64px">SmartBus</h1>
            <h3 class="mb-4 mx-auto" style="font-size: 24px">
              {{ $t("home-title-second") }}
            </h3>
            <h5
              class="mb-5 mx-auto"
              style="
                font-size: 16px;
                font-weight: 300;
                width: 85%;
                line-height: 28px;
              "
            >
              {{ $t("home-title-decription") }}
            </h5>
            <span>{{ $t("try-now") }}</span>
            <div class="mt-4">
              <a
                href="https://apps.apple.com/vn/app/nlt-smartbus/id6453477112"
                target="_blank"
                class="btn btn-lg btn-simple btn-round m-2 btn-down"
                role="button"
              >
                <i class="fab fa-apple mr-2"></i>
                App Store
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.nltbus&hl=vi&gl=US&pli=1"
                target="_blank"
                class="btn btn-lg btn-simple btn-round m-2 btn-down"
                role="button"
              >
                <i class="fab fa-google-play mr-2"></i>
                Google Play
              </a>
            </div>
          </div>
        </div>
      </div>
      <Intro class="fixed-width"></Intro>
      <div class="space-between fixed-width">
        <div class="reveal">
          <span class="text-uppercase title-block">
            {{ $t("home-title-top") }}
          </span>
          <h2 class="content-slogan mt-4">
            {{ $t("home-title-1") }}
          </h2>
        </div>
        <div class="title-secondary reveal col-lg-6 px-0">
          {{ $t("home-decrtiption-1") }}
        </div>
        <img src="@/assets/image/Screen.png" class="reveal" />
      </div>
      <why-form class="space-between fixed-width"></why-form>

      <!-- <why-form></why-form>
      <tab-section></tab-section>
      <comment-form></comment-form> -->
    </div>
    <ParnerForm style="margin-bottom: 66px"></ParnerForm>
    <Feedback style="margin-bottom: 120px" class="reveal"></Feedback>
    <DowloadFooter></DowloadFooter>
    <!-- <download-section></download-section> -->
    <!-- <Scrolltotop></Scrolltotop> -->
  </div>
</template>
<script>
import { Parallax } from "@/components";
import WhyForm from "./components/WhyFormNew.vue";
import Intro from "./components/IntroForm.vue";
import ParnerForm from "./components/ParnerForm.vue";
import Feedback from "./components/Feedback.vue";
import DowloadFooter from "./components/FooterDownload.vue";
// import Scrolltotop from "../components/Button/Scrolltotop.vue";
export default {
  name: "index",
  bodyClass: "index-page",
  components: {
    Parallax,
    WhyForm,
    Intro,
    // Scrolltotop,
    ParnerForm,
    Feedback,
    DowloadFooter,
  },
  data() {
    return {
      windowHeight: window.innerHeight,
    };
  },
  watch: {
    windowHeight() {},
  },
  created() {
    localStorage.setItem("currentUrl", "HOME");
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(value) {
      let reveals = document.querySelectorAll(".reveal");
      let height = window.innerHeight;
      for (let i = 0; i < reveals.length; i++) {
        let windowHeight = window.innerHeight;
        let elementTop = reveals[i].getBoundingClientRect().top;
        let elementVisible = 10;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    },
  },
};
</script>
<style scoped>
.title-content {
  margin: 100px 0 80px 0;
  text-align: center;
}
.btn-down {
  border: 1px solid white !important;
  border-radius: 30px;
  padding: 15px 40px;
  color: white !important;
  transition: 500ms;
  background-color: transparent !important;
  width: 218px;
}
.btn-down:hover {
  background-color: #c71313 !important;
  border: 1px solid #c71313 !important;
}
.desrcep {
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 40px 64px -32px;
  margin-top: -20rem;
  padding: 3rem 1rem 3rem 1rem;
}
.title-block {
  font-size: 12px;
  color: #636466;

  line-height: 28px;
  font-style: normal;
  width: 50%;
}
.content-slogan {
  color: #c71313;
  font-size: 48px !important;
  width: 50%;
  margin: 16px 0 !important;
}
.title-secondary {
  font-size: 16px;
  font-weight: 300;
  color: #636466;
  width: 75%;
  margin-bottom: 40px !important;
}
.space-between {
  margin-bottom: 120px !important;
  padding: 0 10px;
}
.card-content {
  border: 1px solid lightgrey;
  border-radius: 24px;
  overflow: hidden;
}
.border-bottom {
  border-bottom: 1px solid lightgrey;
}
.circle-list {
  width: 32px;
  height: 32px;
  background: #c71313;
  color: white;
  border-radius: 50%;
}
.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}
.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
.fixed-width {
  max-width: 1440px;
  margin: 0 auto;
}
@media screen and (max-width: 364px) {
  .fixed-width {
    max-width: 1440px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 690px) {
  .content-slogan {
    color: #c71313;
    font-size: 48px !important;
    width: 100%;
    margin: 16px 0 !important;
  }
  .content {
    margin: 0 80px !important;
    padding: 0 80px;
  }
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 470px) {
  .btn-down {
    width: 118px;
  }
}
.custom-container {
  /* max-width: 328px; */
  margin: 0 10px;
}
@media only screen and (min-width: 1024px) {
  .custom-container {
    /* max-width: 928px; */
    margin: 0 80px;
  }
}
@media only screen and (min-width: 1440px) {
  .custom-container {
    /* max-width: 1280px; */
    margin: 0 80px;
  }
  .space-between {
    margin-bottom: 120px !important;
    padding: 0 80px;
  }
}
</style>
