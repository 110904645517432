<template>
  <div class="list" v-if="items">
    <div v-for="item,index in items" :key="index" class="d-flex align-items-center mb-3">
      <div class="dot-line mr-3">
        <div class="dot" :class="{'dot-red' : index === 0 || index + 1 === items.length}"></div>
        <div :class="{'line' : !(items.length === index + 1)}"></div>
      </div>
      <p class="station-name">{{ item.BusStopName }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.station-name {
  color: var(--label-color-primary, #000);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.4px;
  margin: 0;
}
.dot-line {
  width: fit-content;
  display: flex;
  justify-content: center;
  position: relative;
}
.list {
  padding: 8px 16px;
}
.dot-red {
  border-radius: 50%;
  background: var(--website-brand-color, #c71313) !important;
  width: 8px;
  height: 8px;
  z-index: 999;
  position: absolute;
  transform: translateY(-50%);
}
.dot {
  border-radius: 50%;
  background: var(--website-brand-color, #c7c7cc);
  width: 8px;
  height: 8px;
  z-index: 999;
  position: absolute;
  transform: translateY(-50%);
}
.line {
  height: 35px;
  width: 2px;
  background: var(--default-system-gray-system-gray-03, #c7c7cc);
  position: absolute;
}
</style>
