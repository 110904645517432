<template>
  <div>
    <div class="header-tab">
      <p class="back-btn" @click="action('TAB', 0)">
        <i class="fas fa-angle-left mr-2"></i>Trở về
      </p>
      <template v-if="stationDetail">
        <p class="tab-title">{{ stationDetail.BusStopName }}</p>
        <p class="route-name">
          {{ stationDetail.BusStopAddress ? stationDetail.BusStopAddress : "" }}
        </p>
        <!-- <b-button class="w-100 btn-changeroute m-0" @click="action('DIRECT')">
          <i class="fas fa-exchange-alt mr-2"></i>Đổi chiều tuyến
        </b-button> -->
      </template>
      <div class="tab-btn-group">
        <div
          class="tab-btn border-right"
          :class="{ 'tab-btn-active': currentTab === 0 }"
          @click="currentTab = 0"
        >
          <!-- <img src="/assets/image/icon/route.svg" style="color: red" /> -->
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="route">
              <path
                id="Icon"
                d="M10.0837 4.16667H10.4457C12.985 4.16667 14.2547 4.16667 14.7367 4.62274C15.1533 5.01697 15.3379 5.59774 15.2255 6.16018C15.0953 6.81085 14.0587 7.54405 11.9856 9.01044L8.59843 11.4062C6.52525 12.8726 5.48865 13.6058 5.35852 14.2565C5.24604 14.8189 5.43067 15.3997 5.84729 15.7939C6.32927 16.25 7.59896 16.25 10.1383 16.25H10.917M7.16699 4.16667C7.16699 5.54738 6.0477 6.66667 4.66699 6.66667C3.28628 6.66667 2.16699 5.54738 2.16699 4.16667C2.16699 2.78596 3.28628 1.66667 4.66699 1.66667C6.0477 1.66667 7.16699 2.78596 7.16699 4.16667ZM18.8337 15.8333C18.8337 17.214 17.7144 18.3333 16.3337 18.3333C14.9529 18.3333 13.8337 17.214 13.8337 15.8333C13.8337 14.4526 14.9529 13.3333 16.3337 13.3333C17.7144 13.3333 18.8337 14.4526 18.8337 15.8333Z"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          Danh sách tuyến
        </div>
        <div
          class="tab-btn"
          :class="{ 'tab-btn-active': currentTab === 1 }"
          @click="currentTab = 1"
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="bus">
              <path
                id="Icon"
                d="M7.58333 15.8333V17.6667C7.58333 17.9 7.58333 18.0167 7.53792 18.1058C7.49797 18.1842 7.43423 18.248 7.35583 18.2879C7.2667 18.3333 7.15002 18.3333 6.91667 18.3333H5.33333C5.09998 18.3333 4.9833 18.3333 4.89417 18.2879C4.81577 18.248 4.75203 18.1842 4.71208 18.1058C4.66667 18.0167 4.66667 17.9 4.66667 17.6667V15.8333M16.3333 15.8333V17.6667C16.3333 17.9 16.3333 18.0167 16.2879 18.1058C16.248 18.1842 16.1842 18.248 16.1058 18.2879C16.0167 18.3333 15.9 18.3333 15.6667 18.3333H14.0833C13.85 18.3333 13.7333 18.3333 13.6442 18.2879C13.5658 18.248 13.502 18.1842 13.4621 18.1058C13.4167 18.0167 13.4167 17.9 13.4167 17.6667V15.8333M3 10H18M3 4.58333H18M5.91667 12.9167H7.16667M13.8333 12.9167H15.0833M7 15.8333H14C15.4001 15.8333 16.1002 15.8333 16.635 15.5609C17.1054 15.3212 17.4878 14.9387 17.7275 14.4683C18 13.9335 18 13.2335 18 11.8333V5.66667C18 4.26654 18 3.56647 17.7275 3.03169C17.4878 2.56129 17.1054 2.17884 16.635 1.93915C16.1002 1.66667 15.4001 1.66667 14 1.66667H7C5.59987 1.66667 4.8998 1.66667 4.36502 1.93915C3.89462 2.17884 3.51217 2.56129 3.27248 3.03169C3 3.56647 3 4.26654 3 5.66667V11.8333C3 13.2335 3 13.9335 3.27248 14.4683C3.51217 14.9387 3.89462 15.3212 4.36502 15.5609C4.8998 15.8333 5.59987 15.8333 7 15.8333Z"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          Danh sách xe
        </div>
      </div>
      <template>
        <b-tabs v-model="currentTab" nav-class="d-none" class="tab-height">
          <b-tab>
            <div
              v-for="(item, index) in listRouteOfStation"
              :key="index"
              class="item-card"
            >
              <div class="container-fluid p-3">
                <div class="row">
                  <div class="col-lg-12 border-bottom pb-3">
                    <strong class="router-name">Tuyến {{ item.Code }} </strong>
                    <p class="m-0 router-info">{{ item.BusRouteName }}</p>
                    <p class="m-0 router-info">
                      <i class="far fa-clock"></i> 5:30 - 22:30
                    </p>
                  </div>
                  <template v-if="item.listBus.length > 0">
                    <div class="col-lg-12 pt-3">
                      <div class="row">
                        <div class="col-lg-3 p-0 pl-1 pr-1">
                          <p class="bagde">
                            {{ item.listBus[0].LicensePlates }}
                          </p>
                        </div>
                        <div class="col-lg-3 p-0">
                          <p class="m-0 router-info">
                            <img src="/assets/image/icon/clock.svg" />
                            {{ item.listBus[0].WaitingTime }} phút
                          </p>
                        </div>
                        <div class="col-lg-3 p-0">
                          <p class="m-0 router-info">
                            <img src="/assets/image/icon/speedometer-02.svg" />
                            {{ item.listBus[0].Speed }} km/h
                          </p>
                        </div>
                        <div class="col-lg-3 p-0">
                          <p class="m-0 router-info">
                            <img src="/assets/image/icon/marker-pin-2.svg" />
                            {{ item.listBus[0].Distances }} km
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <div
              v-for="(item, index) in listRouteOfStation"
              :key="index"
              class="item-card"
            >
              <div class="container-fluid p-3">
                <div class="row">
                  <div class="col-lg-8 pb-3">
                    <strong class="router-name">Tuyến {{ item.Code }} </strong>
                    <p class="m-0 router-info">{{ item.BusRouteName }}</p>
                    <p class="m-0 router-info">
                      <i class="far fa-clock"></i> 5:30 - 22:30
                    </p>
                  </div>
                  <div class="col-lg-4">
                    <p v-if="item.BusDirection" class="m-0 direction-text">
                      {{ item.BusDirection }}
                    </p>
                  </div>
                  <div class="col-lg-12 border-bottom"></div>
                  <template v-if="item.listBus.length > 0">
                    <div
                      v-for="(e, i) in item.listBus"
                      :key="i"
                      class="col-lg-12 pt-3"
                    >
                      <div class="row">
                        <div class="col-lg-3 p-0 pl-1 pr-1">
                          <p class="bagde">{{ e.LicensePlates }}</p>
                        </div>
                        <div class="col-lg-3 p-0">
                          <p class="m-0 router-info">
                            <img src="/assets/image/icon/clock.svg" />
                            {{ e.WaitingTime }} phút
                          </p>
                        </div>
                        <div class="col-lg-3 p-0">
                          <p class="m-0 router-info">
                            <img src="/assets/image/icon/speedometer-02.svg" />
                            {{ e.Speed }} km/h
                          </p>
                        </div>
                        <div class="col-lg-3 p-0">
                          <p class="m-0 router-info">
                            <img src="/assets/image/icon/marker-pin-2.svg" />
                            {{ e.Distances }} km
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stationDetail: {
      type: Object,
      default: null,
    },
    listRouteOfStation: {
      type: Array,
      default: null,
    },
    listBusOfStation: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      currentTab: 0,
    };
  },
  methods: {
    action(action, value) {
      this.$emit("action", action, value);
    },
  },
};
</script>

<style scoped>
.direction-text {
  color: var(--website-brand-color, #c71313);
  font-size: 17px;
  font-weight: 400;
  line-height: 22px; /* 129.412% */
}
.tab-height {
  height: calc(100vh - 270px);
  overflow-y: auto;
  padding: 0;
}
.tab-height::-webkit-scrollbar {
  width: 5px;
  visibility: hidden !important;
}
.tab-height::-webkit-scrollbar-thumb {
  visibility: hidden;
}
.tab-height:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.bagde {
  border-radius: 4px;
  background: var(--default-system-green, #34c759);
  color: var(--system-background-primary, #fff);
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.4px;
  width: fit-content;
  padding: 2px 6px;
  margin: 0;
  width: 100%;
  text-align: center;
}
.router-info {
  color: var(--label-color-primary, #000);
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.4px;
}
.router-name {
  color: var(--label-color-primary, #000);
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.4px;
}
.item-card {
  border-radius: 8px;
  border: 1px solid var(--bottom-bar-border-inactive, #ebebf0);
  background: var(--system-background-primary, #fff);
  /* padding: 16px; */
  margin-bottom: 16px;
  margin-top: 16px;
}
.route-name {
  color: var(--label-color-primary, #000);
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4px;
  margin-bottom: 16px !important;
}
.tab-btn-active {
  background: var(--website-brand-color, #c71313);
  color: var(--system-background-primary, #fff);
}
.tab-btn-active svg path {
  stroke: white !important;
}
.tab-btn {
  padding: 10px 16px;
  width: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.4px;
}
.tab-btn-group {
  display: flex;
  justify-content: space-around;
  border-radius: 8px;
  background: var(--system-background-primary, #fff);
  overflow: hidden;
  /* App/Shadow 1 App */
  box-shadow: 0px 1px 4px 0px rgba(66, 71, 76, 0.12),
    0px 0px 0.5px 0px rgba(198, 204, 198, 0.32);
}
.back-btn {
  color: var(--website-brand-color, #c71313);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.4px;
  cursor: pointer;
}
.header-tab {
  padding: 24px 16px 0;
}
.tab-title {
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: -0.4px;
}
</style>
