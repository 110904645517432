<template>
  <navbar position="fixed" menu-classes="ml-auto" class="bg-color">
    <template>
      <router-link class="nav-link" to="/">
        <div class="d-flex align-items-center justify-content-center">
          <img
            src="@/assets/image/logo/icon-footer.svg"
            height="40px"
            class="mr-2"
          />
          <p style="color: #fff; font-size: 28px; font-weight: bold">
             SmartBus
          </p>
        </div>
      </router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <b-button
          v-if="!user"
          pill
          class="nav-size text-uppercase text-nowrap btn-login my-0"
          @click="isShowLogin = true"
        >
          {{ $t("login") }}
        </b-button>
        <b-nav-item-dropdown
          v-else
          style="min-width: 75px"
          class="nav-size"
          no-caret
        >
          <template slot="button-content">
            <p class="nav-size">
              {{ user.FullName }}
            </p>
          </template>
          <b-dropdown-item @click="handleLogout"> {{ $t("logout") }}</b-dropdown-item>
        </b-nav-item-dropdown>
      </li>
      <li class="nav-item">
        <b-nav-item-dropdown style="min-width: 75px" class="nav-size">
          <template slot="button-content">
            <p class="nav-size text-uppercase">
              <img
                src="@/assets/image/icon/globe-01.svg"
                height="20px"
              />&nbsp;&nbsp;{{ currentLanguage }}
            </p>
          </template>
          <b-dropdown-item @click="handleChangeLanguage('en')" href="#"
            >EN</b-dropdown-item
          >
          <b-dropdown-item @click="handleChangeLanguage('vi')" href="#"
            >VI</b-dropdown-item
          >
        </b-nav-item-dropdown>
      </li>
    </template>
    <modalLogin
      :isShow="isShowLogin"
      @close="isShowLogin = false"
      @create="openCreateCloseLogin"
      @forget="openForgetModal"
    ></modalLogin>
    <modalCreate
      :isShow="isShowCreate"
      @close="isShowCreate = false"
      @openLogin="openLoginCloseCreate"
    ></modalCreate>
    <modalForget
      :isShow="isShowForget"
      @close="isShowForget = false"
      @openLogin="isShowLogin = true"
    ></modalForget>
  </navbar>
</template>

<script>
import modalLogin from "@/components/Modal/LoginModal.vue";
import modalCreate from "@/components/Modal/CreateAccoutModal.vue";
import modalForget from "@/components/Modal/ForgetModal.vue";
import { Navbar } from "@/components";
import { Popover } from "element-ui";
export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {
    Navbar,
    [Popover.name]: Popover,
    modalLogin,
    modalCreate,
    modalForget,
  },
  watch: {
    menuActive() {},
  },
  created() {
    this.getCurrentLanguage();
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
  data() {
    return {
      menuActive: localStorage.getItem("currentUrl"),
      path: this.$root._route.fullPath,
      currentLanguage: "",
      isShowLogin: false,
      isShowCreate: false,
      isShowForget: false,
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null,
    };
  },
  methods: {
    pushUrl(url) {
      localStorage.setItem("currentUrl", url);
    },
    fetchData() {
      this.path = this.$root._route.fullPath;
    },
    getCurrentLanguage() {
      this.currentLanguage = localStorage.getItem("currentLanguage")
        ? localStorage.getItem("currentLanguage")
        : "vi";
      this.$i18n.locale = this.currentLanguage;
    },
    handleChangeLanguage(value) {
      this.currentLanguage = value;
      this.$i18n.locale = value;
      localStorage.setItem("currentLanguage", value);
      location.reload();
    },
    openLoginCloseCreate() {
      this.isShowLogin = true;
      this.isShowCreate = false;
    },
    openCreateCloseLogin() {
      this.isShowLogin = false;
      this.isShowCreate = true;
    },
    openForgetModal() {
      this.isShowForget = true;
      this.isShowLogin = false;
    },
    handleLogout(){
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      location.reload()
    }
  },
};
</script>

<style scoped>
.btn-login {
  border: 2px solid white;
  background-color: #c71313;
  font-size: 15px;
  display: flex;
  padding: 10px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.btn-login:hover {
  background-color: #fff;
  color: #c71313 !important;
  border-color: #c71313;
}
.btn-login:focus {
  background-color: #fff;
  color: #c71313 !important;
  border-color: #c71313;
}
.bg-color {
  background-color: #c71313 !important;
  height: 58px !important;
}
.nav-color {
  color: #c71313 !important ;
  border-bottom: 2px solid #c71313;
}
.nav-size {
  font-size: 14px !important;
  color: #fff !important;
}
.nav-hover:hover {
  /* transition: width 0.3s ease; */
  color: #c71313 !important ;
  /* border-bottom: 2px solid #c71313; */
}
@keyframes underline {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

.nav-hover {
  position: relative;
  display: inline-block;
  color: inherit;
  text-decoration: none;
}

.nav-hover::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0%;
  height: 2px;
  background-color: #c71313;
  color: #c71313 !important ;
  transform-origin: left center;
  transition: width 0.3s;
}

.nav-hover:hover::after {
  width: 100%;
  animation: underline 300ms linear forwards;
}

.nav-hover:not(:hover)::after {
  width: 0%;
}
.download-btn {
  background-color: #c71313;
}
.download-btn:hover {
  background-color: #a11616;
}
/* .nav-item:hover {
  background: white;
} */
</style>
