<template>
  <Navbar position="fixed" :transparent="transparent" menu-classes="ml-auto" :marginNav="50">
    <template>
      <router-link class="nav-link" to="/">
        <div class="d-flex align-items-center justify-content-center">
          <img
            src="@/assets/image/logo/Icon-nav.svg"
            height="40px"
            class="mr-2"
          />
          <p style="color: #c71313; font-size: 32px; font-weight: bold">
             SmartBus
          </p>
        </div>
      </router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <router-link class="nav-link pt-3" to="/" @click="pushUrl('HOME')">
          <p
            :class="path == '/' ? 'nav-color p-2' : 'p-2'"
            class="text-uppercase nav-size nav-hover text-nowrap"
          >
            {{ $t('home') }}
          </p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link pt-3"
          to="/map"
          target="_blank"
          @click="pushUrl('MAP')"
        >
          <p
            :class="path == '/map' ? 'nav-color p-2' : 'p-2'"
            class="text-uppercase nav-size nav-hover text-nowrap"
          >
            {{ $t('web-version') }}
          </p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link pt-3"
          to="/guideline"
          @click="pushUrl('INTRODUCE')"
        >
          <p
            :class="path.includes('guideline') ? 'nav-color p-2' : 'p-2'"
            class="text-uppercase nav-size nav-hover text-nowrap"
          >
            {{ $t('user-guideline') }}
          </p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link pt-3" to="/feature" @click="pushUrl('FEATURE')">
          <p
            :class="path == '/feature' ? 'nav-color p-2' : 'p-2'"
            class="text-uppercase nav-size nav-hover text-nowrap"
          >
            {{ $t('feature') }}
          </p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link pt-3" to="/contact" @click="pushUrl('CONTACT')">
          <p
            :class="path == '/contact' ? 'nav-color p-2' : 'p-2'"
            class="text-uppercase nav-size nav-hover text-nowrap"
          >
            {{ $t('contact') }}
          </p>
        </router-link>
      </li>

      <!-- <li class="nav-item">
        <a class="nav-link pt-3">
          <img src="@/assets/image/internet.png" height="14px"/>
          <p class="p-2 ">VI</p>
        </a>
      </li> -->
      <li class="nav-item ">
        <b-nav-item-dropdown style="padding-top: 12px;min-width: 75px;" class="nav-size" >
          <template slot="button-content">
            <p class="nav-size text-uppercase"><img src="@/assets/image/internet.png" height="14px" />&nbsp;&nbsp;{{ currentLanguage }}</p>
          </template>
          <b-dropdown-item @click="handleChangeLanguage('en')" href="#">EN</b-dropdown-item>
          <b-dropdown-item @click="handleChangeLanguage('vi')" href="#">VI</b-dropdown-item>
        </b-nav-item-dropdown>
      </li>
     
      <li class="nav-item">
        <a
          class="nav-link p-0"
          :class="
            menuActive == 'DOWNLOADAPP'
              ? 'btn btn-success btn-neutral'
              : 'btn btn-danger btn-neutral'
          "
          href="/download-app"
          @click="pushUrl('DOWNLOADAPP')"
          style="background-color: transparent;"
        >
          <b-button
            pill
            class="p-3 px-5 nav-size text-uppercase text-nowrap download-btn"
          >
            {{ $t('download') }}
          </b-button>
        </a>
      </li>
    </template>
  </Navbar>
</template>

<script>
import { Navbar } from "@/components";
import { Popover } from "element-ui";
export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {
    Navbar,
    [Popover.name]: Popover,
  },
  watch: {
    menuActive() {
    },
  },
  created() {
    this.getCurrentLanguage()
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
  data() {
    return {
      menuActive: localStorage.getItem("currentUrl"),
      path: this.$root._route.fullPath,
      currentLanguage:'vi'
    };
  },
  methods: {
    pushUrl(url) {
      localStorage.setItem("currentUrl", url);
    },
    fetchData() {
      this.path = this.$root._route.fullPath;
    },
    getCurrentLanguage(){
      this.currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'vi'
      this.$i18n.locale = this.currentLanguage;
    },
    handleChangeLanguage(value){
      this.currentLanguage = value
      this.$i18n.locale = value;
      localStorage.setItem("currentLanguage", value);
      location.reload();
      // console.log(value)
    },
  },
};
</script>

<style scoped>
.nav-color {
  color: #c71313 !important ;
  border-bottom: 2px solid #c71313;
}
.nav-size {
  font-size: 14px !important;
}
.nav-hover:hover {
  /* transition: width 0.3s ease; */
  color: #c71313 !important ;
  /* border-bottom: 2px solid #c71313; */
}
@keyframes underline {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

.nav-hover {
  position: relative;
  display: inline-block;
  color: inherit;
  text-decoration: none;
}

.nav-hover::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0%;
  height: 2px;
  background-color: #c71313;
  color: #c71313 !important ;
  transform-origin: left center;
  transition: width 0.3s;
}

.nav-hover:hover::after {
  width: 100%;
  animation: underline 300ms linear forwards;
}

.nav-hover:not(:hover)::after {
  width: 0%;
}
.download-btn {
  background-color: #c71313;
}
.download-btn:hover {
  background-color: #a11616;
}
/* .nav-item:hover {
  background: white;
} */
</style>
