import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index';
import Map from './pages/WebApp';
import Introduce from './pages/guideline';
import Contact from './pages/Contact';
import Feature from './pages/Feature';
import DownloadApp from './pages/DownloadApp';
import MainNavbar from './layout/MainNavbar';
import AppNavbar from './layout/AppNavbar';
import MainFooter from './layout/MainFooter';
import Scrolltotop from './components/Button/Scrolltotop'

import routerGuideline from './pages/guideline/router'
Vue.use(Router);

export default new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      components: { default: Index, header: MainNavbar, footer: MainFooter ,totop: Scrolltotop },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/map',
      name: 'map',
      components: { default: Map, header: AppNavbar},
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/guideline',
      name: 'guideline',
      components: { default: Introduce, header: MainNavbar, footer: MainFooter ,totop: Scrolltotop },
      redirect: "/guideline/main",
      children: routerGuideline,
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/contact',
      name: 'contact',
      components: { default: Contact, header: MainNavbar, footer: MainFooter ,totop: Scrolltotop },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/feature',
      name: 'feature',
      components: { default: Feature, header: MainNavbar, footer: MainFooter ,totop: Scrolltotop },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/download-app',
      name: 'DownloadApp',
      components: { default: DownloadApp, header: MainNavbar, footer: MainFooter ,totop: Scrolltotop },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
