<template>
    <div>
      <div class="page-header clear-filter">
        <parallax
          class="page-header-image"
          style="background-image: url('/assets/image/bg/bg_19.png')"
        >
        </parallax>
        <div>
          <div class="container-fluid">
            <div class="row justify-content-center" style="margin-top: 200px">
              <div class="text-center col-md-12 col-lg-9">
                <h3 class="title font-64">{{ $t("guideline") }}</h3>
                <p class="font-24">
                  {{ $t("guideline-description") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="max-width: 1440px; margin: 120px auto; padding: 0 80px !important"
        class="px-4"
      >
        <router-view/>
      </div>
      <DowloadFooter></DowloadFooter>
    </div>
  </template>
  <script>
  import DowloadFooter from "../components/FooterDownload.vue";
  export default {
    name: "introduce",
    bodyClass: "introduce-page",
    components: {
      DowloadFooter,
    },
  };
  </script>
  <style scoped>
  .font-64 {
    font-size: 64px;
  }
  .font-24 {
    font-size: 24px;
  }
  .title-block {
    font-size: 12px;
    font-weight: 300;
    color: #636466;
    width: 75%;
  }
  .title-secondary {
    font-size: 16px;
    font-weight: 300;
    color: #636466;
    width: 75%;
    margin-bottom: 40px !important;
  }
  .content-slogan {
    color: #c71313;
    font-size: 48px !important;
    width: 50%;
    margin: 16px 0 !important;
  }
  .content-card {
    border: 1px solid #e6e8ec;
    border-radius: 24px;
    overflow: hidden;
    transition: 500ms;
    cursor: pointer;
  }
  .content-card p {
    height: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .content-card:hover {
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
  }
  </style>
  