<template>
  <div>
    <div class="reveal">
      <span class="text-uppercase title-block ">
        {{ $t('home-title-top-2') }}
      </span>
      <h2 class="content-slogan mt-4 ">{{ $t('home-title-2') }}</h2>
    </div>
    <div class="title-secondary mb-3 reveal col-lg-6 px-0">
      {{ $t('home-decrtiption-2') }}
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6 col-md-6 pl-0">
          <div v-for="item,index in text" :key="index" class="reveal">
            <div class="d-flex align-items-center">
              <div
                class="circle-list text-center d-flex justify-content-center align-items-center"
              >
                {{ index + 1 }}
              </div>
              <h3 class="h3 m-0 ml-2 list-items">{{ item.title }}</h3>
            </div>
            <p class="list-contents">
              {{ item.content }}
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 reveal">
          <div class="card-content">
            <img src="@/assets/image/sep.png" width="100%" />
            <div class="m-3">
              <div class="border-bottom pb-3">
                <h3 class="mb-2">{{ $t('home-boss-name') }}</h3>
                <span>
                  <a href="https://nlt-group.com/" target="_blank">{{ $t('home-boss') }}</a>
                </span>
              </div>
              <h3 class="mt-2 mb-2 font-size-24 font-weight-bold">
                “{{ $t('home-boss-slogan')}}”
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data(){
    return{
      text: [
        {
          title: this.$t('advantage.1'),
          content: this.$t('advantage.content-1'),
        },
        {
          title: this.$t('advantage.2'),
          content: this.$t('advantage.content-2'),
        },
        {
          title: this.$t('advantage.3'),
          content: this.$t('advantage.content-3'),
        },
        {
          title: this.$t('advantage.4'),
          content: this.$t('advantage.content-4'),
        },
        {
          title: this.$t('advantage.5'),
          content: this.$t('advantage.content-5'),
        },
        {
          title: this.$t('advantage.6'),
          content: this.$t('advantage.content-6'),
        }
      ]
    }
  }
};
</script>

<style scoped>
.title-block {
  font-size: 12px;
  font-weight: 300;
  color: #636466;
  width: 75%;
}
.title-secondary {
  font-size: 16px;
  font-weight: 300;
  color: #636466;
  width: 75%;
  margin-bottom: 40px !important;
}
.content-slogan {
  color: #c71313;
  font-size: 48px !important;
  width: 50%;
  margin: 16px 0 !important;
}
.card-content {
  border: 1px solid #E6E8EC;
  border-radius: 24px;
  overflow: hidden;
}
.border-bottom {
  border-bottom: 1px solid lightgrey;
}
.circle-list {
  width: 32px;
  height: 32px;
  background: #c71313;
  color: white;
  border-radius: 50%;
}
.list-items {
  font-size: 24px;
  font-weight: 800;
}
.list-contents {
  margin-top: 12px;
  font-size: 16px;
}
.font-size-24 {
  font-size: 24px;
}
.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}
.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
@media screen and (max-width: 390px) {
  .content-slogan {
    color: #c71313;
    font-size: 48px !important;
    width: 100%;
    margin: 16px 0 !important;
  }
}
</style>
