<template>
  <div>
    <div class="page-header clear-filter">
      <parallax
        class="page-header-image"
        style="background-image: url('/assets/image/bg/bg_19.png')"
      >
      </parallax>
      <div>
        <div class="container">
          <div class="row justify-content-md-center" style="margin-top: 200px">
            <div class="text-center col-md-12 col-lg-9">
              <h3 class="title font-64">{{ $t('contact-with') }}</h3>
              <p class="font-24">
                {{ $t('contact-title') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section" style="padding: 0 0 0 0">
      <div>
        <!-- <div class="button-container">
          <a
            href="#"
            class="btn btn-info btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Facebook"
          >
            <i class="fab fa-facebook-square"></i>
          </a>
          <a
            href="#"
            class="btn btn-danger btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Youtube"
          >
            <i class="fab fa-youtube"></i>
          </a>
        </div> -->
        <about-form></about-form>
        <feedback-form></feedback-form>
        <DowloadFooter></DowloadFooter>
      </div>
    </div>
  </div>
</template>
<script>
import FeedbackForm from "./components/FeedbackForm.vue";
import AboutForm from "./components/AboutForm.vue";
import DowloadFooter from "./components/FooterDownload.vue";
export default {
  name: "contact",
  bodyClass: "contact-page",
  components: {
    FeedbackForm,
    AboutForm,
    DowloadFooter,
  },
};
</script>
<style scoped>
.font-64 {
  font-size: 64px;
}
.font-24 {
  font-size: 24px;
}
</style>
