<template>
    <div>
        <div class="page-header clear-filter" >
            <parallax class="page-header-image" style="background-image: url('/assets/image/bg/bg_19.png')">
            </parallax>
            <download-app-form></download-app-form>
        </div>
    </div>
</template>
<script>
import DownloadAppForm from './components/DownloadAppForm';

export default {
    name: 'download-app',
    bodyClass: 'download-app-page',
    components: {
        DownloadAppForm
    }
};
</script>