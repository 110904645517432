<template>
  <div>
    <b-table
      class="table-height-ndt"
      thead-class="text-nowrap thead-class"
      :items="items"
      :fields="fields"
      stripeds
      responsive
    >
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
    fields: {
      type: Array,
    }
  },
  data() {
    return {
      // items: [
      //   {
      //     name: "Stephen Hawking",
      //     id: 1,
      //     type1: "5:00",
      //     type2a: "5:00",
      //     type2b: "5:00",
      //     type2c: "5:00",
      //     type3: "5:00",
      //   },
      //   {
      //     name: "Johnny Appleseed",
      //     id: 2,
      //     type1: "5:00",
      //     type2a: "5:00",
      //     type2b: "5:00",
      //     type2c: "5:00",
      //     type3: "5:00",
      //   },
      //   {
      //     name: "George Washington",
      //     id: 3,
      //     type1: "5:00",
      //     type2a: "5:00",
      //     type2b: "5:00",
      //     type2c: "5:00",
      //     type3: "5:00",
      //   },
      //   {
      //     name: "Albert Einstein",
      //     id: 4,
      //     type1: "5:00",
      //     type2a: "5:00",
      //     type2b: "5:00",
      //     type2c: "5:00",
      //     type3: "5:00",
      //   },
      //   {
      //     name: "Isaac Newton",
      //     id: 5,
      //     type1: "5:00",
      //     type2a: "5:00",
      //     type2b: "5:00",
      //     type2c: "5:00",
      //     type3: "5:00",
      //   },
      // ],
      // fields: [
      //   // "name",
      //   { key: "id", label: "Chuyến số", class: "text-center" },
      //   { key: "type1", label: "Đồng Hới", class: "text-center" },
      //   { key: "type2a", label: "Ba Đồn", class: "text-center" },
      //   { key: "type2b", label: "Vũng Chùa", class: "text-center" },
      // ],
    };
  },
};
</script>

<style >
.thead-class {
  color: var(--label-color-primary, #000);
  text-align: center;

  /* Base/14px - Semi Bold */
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
}
.table-height-ndt {
  height: calc(100vh - 270px - 163px);
  width: 350px !important;
  overflow-y: auto;
}
.table-height-ndt::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute !important;
  transition: transform 0.3s;
}
.table-height-ndt::-webkit-scrollbar-thumb {
  visibility: hidden;
}
.table-height-ndt:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
</style>
