<template>
	<div>
		<div class="custom-container">
			<span class="text-uppercase title-block reveal">{{ $t("feedback-customer") }}</span>
			<h2 class="content-slogan mt-4 reveal">{{ $t("feedback-customer-comment") }}</h2>
			<div class="title-secondary reveal col-lg-6 px-0">
				{{ $t("feedback-customer-description") }}
			</div>
		</div>
		<div class="mt-4 reveal">
			<VueSlickCarousel v-bind="setting">
				<div v-for="(item, index) in feedbacks" :key="index" class="text-center">
					<div class="card-content">
						<div style="overflow: hidden">
							<img :src="`${item.img}`" width="100%" height="332px" />
						</div>
						<div class="m-3 text-left">
							<div class="d-flex border-bottom mb-2">
								<div class="avatar">
									<img :src="`${item.avatar}`" class="mr-2" height="60px" width="60px" />
								</div>
								<div class="pb-3 text-left ml-3 mb-3">
									<h5 class="mb-2">{{ item.name }}</h5>
									<!-- <span>{{ item.position }}</span> -->
								</div>
							</div>
							<span class="mt-2 mb-2 text-left">
								{{ item.comment }}
							</span>
						</div>
					</div>
				</div>
			</VueSlickCarousel>
		</div>
	</div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
	components: {VueSlickCarousel},
	data() {
		return {
			feedbacks: [
				{
					name: "Trần Hoài An",
					avatar: "/assets/image/avatar1.png",
					position: "FrontEnd",
					img: "/assets/image/bg/bg_2.jpg",
					comment: "Đi du lịch bằng ứng dụng này khỏi sợ bị lạc kkk",
				},
				{
					name: "Vân Trang",
					avatar: "/assets/image/avatar2.png",
					position: "Backend",
					img: "/assets/image/bg/bg_3.png",
					comment: "Mình dùng vài lần đầu có tình trạng giật lag, sau khi feedback thì tình trạng này không còn nữa 👏 Hoan hô tinh thần của đơn vị chủ quản",
				},
				{
					name: "Nguyễn Thanh Phong",
					avatar: "/assets/image/avatar7.png",
					position: "Giám đốc FrontEnd",
					img: "/assets/image/bg/bg_2.jpg",
					comment: "Cá nhân tôi rất hay đón xe buýt để đi dạo quanh thành phố. Cũng chill lắm 😊",
				},
				{
					name: "Lê Thị Tuyết Nghi",
					avatar: "/assets/image/avatar5.png",
					position: "Vô tri",
					img: "/assets/image/bg/bg_3.png",
					comment: "Mình là sinh viên, mình cảm thấy ứng dụng này thật sự hữu ích.",
				},
				{
					name: "Nguyễn Thái Đức",
					avatar: "/assets/image/avatar4.png",
					position: "Backend",
					img: "/assets/image/bg/bg_2.jpg",
					comment: "Cần tham khảo thêm các app khác để cập nhật nhiều hơn tính năng! Điểm cộng là không có quảng cáo 👍",
				},
				{
					name: "Minh Trí",
					avatar: "/assets/image/avatar3.png",
					position: "Tester - Trưởng hội Anti",
					img: "/assets/image/story_0.jpg",
					comment: "Rất tiện lợi, biết khi nào xe buýt tới để chủ động hơn.",
				},
				{
					name: "An Chi",
					avatar: "/assets/image/avatar6.png",
					position: "Designer",
					img: "/assets/image/story_3.jpg",
					comment: "Xe buýt là phương tiện di chuyển chính của mình, từ ngày có ứng dụng này đón xe tiện lợi hơn rất nhiều!",
				},
			],
			setting: {
				arrows: true,
				dots: false,
				infinite: true,
				slidesToShow: 4,
				autoplay: true,
				speed: 5000,
				autoplaySpeed: 5000,
				waitForAnimate: true,
				cssEase: "linear",
				centerPadding: "0px",
				pauseOnHover: false,
				responsive: [
					{
						breakpoint: 1540,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 1,
						},
					},
					{
						breakpoint: 1314,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1,
						},
					},
					{
						breakpoint: 888,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
						},
					},
				],
			},
		};
	},
};
</script>

<style scoped>
.title-block {
	font-size: 12px;
	font-weight: 300;
	color: #636466;
	width: 75%;
}
.title-secondary {
	font-size: 16px;
	font-weight: 300;
	color: #636466;
	width: 75%;
	margin-bottom: 40px;
}
.content-slogan {
	color: #c71313;
	font-size: 48px !important;
	width: 50%;
	margin: 16px 0 !important;
}
.card-content {
	border: 1px solid #e6e8ec;
	border-radius: 24px;
	overflow: hidden;
	width: 350px;
	min-height: 548px;
}
.border-bottom {
	border-bottom: 1px solid lightgrey;
}
.avatar {
	vertical-align: middle;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	overflow: hidden;
}
.reveal {
	position: relative;
	transform: translateY(150px);
	opacity: 0;
	transition: 1s all ease;
}
.reveal.active {
	transform: translateY(0);
	opacity: 1;
}
.custom-container {
  /* max-width: 328px; */
  padding: 0 20px;
  max-width: 1440px;
  margin: 0 auto 40px;
}
@media only screen and (min-width: 1024px) {
  .custom-container {
    /* max-width: 928px; */
    padding: 0 80px;
    max-width: 1440px;
    margin: 0 auto 40px;
  }
}
@media only screen and (min-width: 1440px) {
  .custom-container {
    /* max-width: 1280px; */
    padding: 0 80px;
    max-width: 1440px;
    margin: 0 auto 40px;
  }
  .card-content {
	border: 1px solid #e6e8ec;
	border-radius: 24px;
	overflow: hidden;
	width: 400px;
	min-height: 548px;
}
}
</style>
