<template>
  <div>
    <div class="main space-between">
      <div class="section" style="background-color: #fcfcfc">
        <div class="container-fluid">
          <div class="col-lg-10 mx-auto desrcep">
            <VueSlickCarousel v-bind="setting">
              <div
                v-for="(item, index) in contentSlide"
                :key="index"
                class="slide-card text-center pr-5 pl-5"
              >
                <img :src="item.img" height="40px" class="mx-auto" />
                <p
                  class="font-weight-bold mt-1 mx-auto w-75"
                  style="font-size: 24px; font-weight: 500"
                >
                  {{ item.title }}
                </p>
                <span style="font-size: 16px">
                  {{ item.content }}
                </span>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: { VueSlickCarousel },
  data() {
    return {
      contentSlide: [
        {
          title: this.$t("home-feature.feature-1"),
          img: "/assets/image/Map.png",
          content: this.$t("home-feature.feature-decription-1"),
        },
        {
          title: this.$t("home-feature.feature-2"),
          img: "/assets/image/Clock.png",
          content: this.$t("home-feature.feature-decription-2"),
        },
        {
          title: this.$t("home-feature.feature-3"),
          img: "/assets/image/Bus (2).png",
          content: this.$t("home-feature.feature-decription-3"),
        },
      ],
      setting: {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 3,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.title-content {
  margin: 9rem 0 0 0;
  text-align: center;
}
.name-font {
}
.btn-down {
  border: 1px solid white !important;
  color: white !important;
}
.desrcep {
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 40px 64px -32px rgba(0, 0, 0, 0.1);
  margin-top: -15rem;
  padding: 3rem 1rem 3rem 1rem;
}
.title-block {
  font-size: 12px;
  font-weight: 300;
  color: #636466;
  width: 75%;
}
.title-secondary {
  font-size: 16px;
  font-weight: 300;
  color: #636466;
  width: 75%;
}
.content-slogan {
  color: #c71313;
  font-size: 48px !important;
  width: 50%;
  margin: 16px 0 !important;
}
.space-between {
  margin-bottom: 120px;
}
.card-content {
  border: 1px solid lightgrey;
  border-radius: 24px;
  overflow: hidden;
}
.border-bottom {
  border-bottom: 1px solid lightgrey;
}
.circle-list {
  width: 32px;
  height: 32px;
  background: #c71313;
  color: white;
  border-radius: 50%;
}
.slide-card {
  display: flex;
  justify-content: center;
}
</style>
