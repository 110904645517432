<template>
  <div style="max-width: 1440px; margin: 0 auto">
    <div class="container-fluid">
      <div
        v-for="(item, index) in content"
        :key="index"
        class="feature-card reveal"
      >
        <template v-if="index % 2 === 0">
          <div class="m-40">
            <h3 class="content-slogan pb-4">
              {{ item.title }}
            </h3>
            <div class=" title-secondary" style="font-weight: 300;">
              {{ item.content }}
            </div>
          </div>
          <div class="img-card" :style="`background-image: url('${item.img}')`">
            <!-- <img class="w-100" :src="item.img" /> -->
          </div>
        </template>
        <template v-else>
          <div class="img-card" :style="`background-image: url('${item.img}')`">
            <!-- <img class="w-100" :src="item.img" /> -->
          </div>
          <div class="m-40">
            <h3 class="content-slogan pb-4">
              {{ item.title }}
            </h3>
            <div class="title-secondary">
              {{ item.content }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [
        {
          title: this.$t('feature-title1'),
          img: "/assets/image/bg/bg_6.png",
          content: this.$t('feature-title1-content'),
        },
        {
          title: this.$t('feature-title2'),
          img: "/assets/image/bg/bg_12.png",
          content: this.$t('feature-title2-content'),
        },
        {
          title: this.$t('feature-title3'),
          img: "/assets/image/bg/bg_11.png",
          content: this.$t('feature-title3-content'),
        },
        {
          title: this.$t('feature-title4'),
          img: "/assets/image/bg/bg_10.png",
          content: this.$t('feature-title4-content'),
        },
      ],
    };
  },
};
</script>

<style scoped>
.feature-card {
  display: flex;
  flex-direction: row;
  border: 1px solid #E6E8EC;
  border-radius: 24px;
  max-width: 1175px;
  overflow: hidden;
  margin: 0 auto 120px;
  height: 400px;
}
.img-card {
  width: 522px;
  background-position: center;
  background-size: cover;
}
.content-slogan {
  color: #c71313;
  font-size: 48px !important;
  border-bottom: 1px solid lightgrey;
  margin: 16px 0 !important;
}
.title-secondary {
  font-size: 16px;
  font-weight: 300;
  color: #636466;
  margin: 24px 0 0;
}
.m-40 {
  margin: 40px;
  width: 50%;
}
.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}
.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
@media screen and (max-width: 1077px) {
  .feature-card {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgrey;
    border-radius: 24px;
    max-width: 645px;
    overflow: hidden;
    margin: 0 auto 120px;
  }
  .img-card {
    width: 100%;
    height: 400px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
  }
  .m-40 {
    margin: 40px;
    width: 85% ;
    order: 1;
  }
}
</style>
