<template>
  <div>
    <div class="custom-container">
      <div class="reveal">
        <span class="text-uppercase title-block">{{
          $t("home-title-top-3")
        }}</span>
        <h2 class="content-slogan m-0">{{ $t("home-title-3") }}</h2>
      </div>
      <div class="title-secondary reveal col-lg-6 px-0">
        {{ $t("home-decrtiption-3") }}
      </div>
    </div>
    <div class="reveal">
      <VueSlickCarousel v-bind="setting">
        <div class="text-center logo-card mb-5">
          <img src="@/assets/image/Logo.svg" class="parner logo-height" />
        </div>
        <div class="text-center logo-card">
          <img src="@/assets/image/Logo (1).png" class="parner logo-height" />
        </div>
        <div class="text-center logo-card">
          <img src="@/assets/image/Logo.svg" class="parner logo-height" />
        </div>
        <div class="text-center logo-card">
          <img src="@/assets/image/Logo (1).png" class="parner logo-height" />
        </div>
        <div class="text-center logo-card">
          <img src="@/assets/image/Logo.svg" class="parner logo-height" />
        </div>
        <div class="text-center logo-card">
          <img src="@/assets/image/Logo (1).png" class="parner logo-height" />
        </div>
        <div class="text-center logo-card">
          <img src="@/assets/image/Logo.svg" class="parner logo-height" />
        </div>
        <div class="text-center logo-card">
          <img src="@/assets/image/Logo (1).png" class="parner logo-height" />
        </div>
        <!-- <div class="text-center">
          <img src="@/assets/image/Logo (2).png" class="parner logo-height" />
        </div>
        <div class="text-center">
          <img src="@/assets/image/Logo.png" class="parner logo-height" />
        </div>
        <div class="text-center">
          <img src="@/assets/image/Logo (1).png" class="parner logo-height" />
        </div>
        <div class="text-center">
          <img src="@/assets/image/Logo (2).png" class="parner logo-height" />
        </div>
        <div class="text-center">
          <img src="@/assets/image/Logo.png" class="parner logo-height" />
        </div>
        <div class="text-center">
          <img src="@/assets/image/Logo (2).png" class="parner logo-height" />
        </div> -->
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: { VueSlickCarousel },
  data() {
    return {
      setting: {
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 6,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        waitForAnimate: true,
        cssEase: "linear",
        centerPadding: "0px",
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 1540,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1130,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 886,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 644,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.logo-card {
  border-radius: 24px;
  border: 1px solid var(--website-gray, #e6e8ec);
  background: var(--website-white, #fff);
  height: 240px;
  width: 240px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  transition: 500ms;
}

.title-block {
  font-size: 12px;
  font-weight: 300;
  color: #636466;
  width: 75%;
}
.title-secondary {
  font-size: 16px;
  font-weight: 300;
  color: #636466;
  /* margin-bottom: 30px !important; */
  width: 75%;
}
.content-slogan {
  color: #c71313;
  font-size: 48px !important;
  width: 50%;
  margin: 16px 0 !important;
}
.parner {
  border-radius: 25px;
  transition: 500ms;
}
.logo-card:hover {
  box-shadow: 0px 40px 64px -32px rgba(0, 0, 0, 0.1);

  z-index: 9;
}
.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}
.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
.logo-height {
  width: 150px;
  padding: 10px;
}
@media screen and (max-width: 390px) {
  .content-slogan {
    color: #c71313;
    font-size: 48px !important;
    width: 100%;
    margin: 16px 0 !important;
  }
}
@media screen and (max-width: 364px) {
  .logo-height {
    width: 120px;
  }
}
.custom-container {
  /* max-width: 328px; */
  padding: 0 20px;
  max-width: 1440px;
  margin: 0 auto 40px;
}
@media only screen and (min-width: 1024px) {
  .custom-container {
    /* max-width: 928px; */
    padding: 0 80px;
    max-width: 1440px;
    margin: 0 auto 40px;
  }
}
@media only screen and (min-width: 1440px) {
  .custom-container {
    /* max-width: 1280px; */
    padding: 0 80px;
    max-width: 1440px;
    margin: 0 auto 40px;
  }
}
</style>
