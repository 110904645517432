<template>
  <div>
    <div class="container">
      <div class="row justify-content-md-center" style="margin-top: 200px;">
        <div class="text-center col-md-12 col-lg-9">
          <h3 class="title header-content">{{ $t('download-title') }}</h3>
          <p class="font-24">{{ $t('download-description') }}</p>
          <p class="font-16 mb-5">{{ $t('download-description2') }}</p>
          <p class="font-16">{{ $t('download-now') }}</p>
        </div>
        <div class="text-center col-md-12 col-lg-8">
          <a href="https://apps.apple.com/vn/app/nlt-smartbus/id6453477112" class="btn btn-danger btn-lg btn-simple btn-round m-2 btn-down" role="button">
            <i class="fab fa-apple mr-2"></i>
            App Store
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.nltbus&hl=vi&gl=US&pli=1" target="_blank" class="btn btn-danger btn-lg btn-simple btn-round m-2 btn-down" role="button">
            <i class="fab fa-google-play mr-2"></i>
            Google Play
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.header-content{
  font-size: 64px;
}
.btn-down {
  border: 1px solid white !important;
  border-radius: 30px;
  padding: 15px 40px;
  color: white !important;
  transition: 500ms;
  background-color: transparent !important;
}
.btn-down:hover{
  background-color: #c71313 !important;
  border: 1px solid #c71313 !important;
}
.font-24{
  font-size: 24px;
}
.font-16{
  font-size: 16px;
}
</style>
