<template>
  <div
    class=""
    style="
      background-image: url('/assets/image/Img_3.png');
      background-size: cover;
      background-position: top center;
      min-height: 782px;
      display: flex;
      align-items: center;
    "
  >
    <div class="container">
      <div class="row">
        <!-- <card class="card-feedback" header-classes="text-center" color="red">
          <template slot="header">
            <h3 class="card-title title-up mt-0">GÓP Ý</h3>
          </template>
          <template>
            <fg-input class="no-border" placeholder="Họ và tên..." addon-left-icon="now-ui-icons users_circle-08">
            </fg-input>
            <fg-input class="no-border" placeholder="Số điện thoại..." addon-left-icon="now-ui-icons text_caps-small">
            </fg-input>
            <fg-input class="no-border" placeholder="Email" addon-left-icon="now-ui-icons ui-1_email-85">
            </fg-input>
            <div class="textarea-container">
              <textarea class="form-control" name="name" rows="4" cols="80" placeholder="Ý kiến đóng góp..."></textarea>
            </div>
          </template>
          <div class="card-footer text-right pt-0 pb-2">
            <n-button type="neutral" round size="lg">
              <i class="far fa-envelope mr-2"></i>
              Gửi
            </n-button>
          </div>
        </card> -->
        <div class="col-lg-6"></div>
        <div class="col-lg-6">
          <div class="form-card">
            <h2 style="color: #c71313">{{ $t("comment") }}</h2>
            <b-form @submit="onSubmit" @reset="onReset">
              
              <b-form-group
                id="input-group-1"
                :label="$t('name')"
                label-for="input-1"
                :invalid-feedback="invalidFeedback"
                :state="state"
              >
                <b-form-input
                  id="input-1"
                  :state="state"
                  v-model="name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                :label="$t('mail')"
                label-for="input-2"
                :invalid-feedback="invalidFeedback"
              >
                <b-form-input id="input-2" v-model="email"></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                :label="$t('sdt')"
                label-for="input-3"
                :invalid-feedback="invalidFeedback"
                :state="state"
              >
                <b-form-input
                  id="input-3"
                  :state="state"
                  v-model="phoneNumber"
                ></b-form-input>
              </b-form-group>
              <!-- <b-form-group
                id="input-group-2"
                :label="$t('content')"
                label-for="input-3"
                :invalid-feedback="invalidFeedback"
                :state="state"
              >
                <b-form-select
                  id="input-3"
                  :state="state"
                  :options="listContentComment"
                  v-model="contentFeedback"
                ></b-form-select>
              </b-form-group> -->
              <b-form-group
                id="fieldset-1"
                label="Tiêu đề góp ý:"
                label-for="input-1"
                label-class=""
                :invalid-feedback="invalidFeedback"
                :state="state"
              >
                <b-form-select
                  id="input-1"
                  v-model="choseFeedback"
                  :options="listContentComment"
                  :state="state"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                v-if="choseFeedback && choseFeedback.toUpperCase()==='KHÁC'"
                id="fieldset-1"
                label="Tiêu đề khác:"
                label-for="input-1"
                label-class=""
                :invalid-feedback="invalidFeedback"
                :state="state"
              >
                <b-form-input
                  id="input-1"
                  v-model="otherTitle"
                  :options="listContentComment"
                  :state="state"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                :label="$t('comment-content')"
                label-for="input-3"
                :invalid-feedback="invalidFeedback"
                :state="state"
              >
                <b-form-textarea
                  rows="5"
                  :state="state"
                  id="input-3"
                  v-model="note"
                ></b-form-textarea>
              </b-form-group>
              <!-- <b-form-group
                id="fieldset-1"
                label="Góp ý"
                label-for="input-1"
                label-class="lable-class"
              >
                <VueFileAgent
                  :multiple="true"
                  :deletable="true"
                  :helpText="'Chọn hình ảnh'"
                  @select="filesSelected($event)"
                ></VueFileAgent>
              </b-form-group> -->
              <b-button type="submit" class="w-100 submit-btn" pill>{{
                $t("send")
              }}</b-button>
            </b-form>
          </div>
        </div>
        <b-modal
          id="succes-modal"
          size="md"
          hide-footer
          hide-header
          centered
          scrollable
          content-class="border-radius-24"
          v-model="isShow"
        >
          <div class="text-center">
            <img src="/assets/image/icon/c-check 1.svg" class="mb-4" />
            <p class="succes-title mb-4">Cám ơn</p>
            <p class="mb-4" style="font-size: 16px">
              Chúng tôi đã nhận được tin nhắn của bạn. Nếu yêu cầu là khẩn cấp,
              vui lòng gọi đến số
              <strong class="font-weight-bold">(+84) 911 379 581</strong> để nói
              chuyện với một trong những nhân viên của chúng tôi. Nếu không,
              chúng tôi sẽ liên hệ với bạn qua email trong 2 ngày làm việc.
            </p>
            <b-button
              pill
              style="background-color: #c71313"
              class="px-5"
              @click="$bvModal.hide('succes-modal')"
              >ĐÃ HIỂU
            </b-button>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, FormGroupInput, Button } from "@/components";
import RepositoryFactory from "@/repository/factory";
const categoryContentComments = RepositoryFactory.get(
  "categoryContentComments"
);
const guestContacts = RepositoryFactory.get("guestContacts");
export default {
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  computed: {
    invalidFeedback() {
      return this.$t("validate-feedback");
    },
  },
  watch: {
    isShow(to) {
      if (to === false) {
        this.name = null;
        this.email = null;
        this.phoneNumber = null;
        this.note = null;
        this.state = null;
      }
    },
  },
  data() {
    return {
      isShow: false,
      state: null,
      name: null,
      email: null,
      phoneNumber: null,
      contentFeedback: "",
      choseFeedback: null,
      note: null,
      listContentComment: [],
      otherTitle: null,
    };
  },
  created() {
    this.getListContentComment();
  },
  methods: {
    filesSelected() {},
    async getListContentComment() {
      let body = {
        GeoCode: "BusContentComment",
      };
      let res = await categoryContentComments.GetActive(body);
      this.listContentComment = res.status
        ? res.data.map((e) => {
            return { text: e.Name, value: e.Name };
          })
        : [];
    },
    async onSubmit(event) {
      event.preventDefault();
      if (this.name && this.phoneNumber && this.note) {
        this.state = true;
      } else {
        this.state = false;
      }
      if (!this.state) return;
      let body = {
        ServerName: localStorage.getItem("server"),
        GeoCode: "BusContentComment",
        Name: this.name,
        Phone: this.phoneNumber,
        Email: this.email,
        Subject: this.choseFeedback.toUpperCase() === 'KHÁC' ? this.otherTitle :  this.choseFeedback,
        Comment: this.note,
        Images: "image.jpeg",
      };
      let res = await guestContacts.AddNoLogin(body);
      if (res.status) {
        this.$bvModal.show("succes-modal");
      }
    },
    onReset() {},
  },
};
</script>
<style>
.border-radius-24 {
  border-radius: 24px !important;
}
</style>
<style scoped>
.form-card {
  background-color: white;
  border-radius: 24px;
  padding: 54px 32px;
}
.submit-btn {
  background-color: #c71313;
}
.submit-btn:hover {
  background-color: #9f0f0f;
}
</style>
