<template>
  <div class="d-flex align-items-center" style="background-color: transparent">
    <div class="side-bar">
      <slot></slot>
    </div>
    <div class="btn-show-menu" @click="handleShow">
      <i v-if="isHide" class="fas fa-caret-right text-white"></i>
      <i v-else class="fas fa-caret-left text-white"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isHide:{
      type: Boolean
    }
  },
  data() {
    return {
      // isHide: false,
    };
  },
  methods: {
    handleShow() {
      //this.isHide = !this.isHide;
      this.$emit("handleClick");
    },
  },
};
</script>

<style scoped>
.side-bar {
  width: 412px;
  height: calc(100vh - 58px);
  background: var(--system-background-secondary, #f7f7f7);
}
.btn-show-menu {
  width: 18px;
  height: 60px;
  background: var(--website-brand-color, #c71313);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 7px 7px 0px;
}
</style>
