<template>
  <div>
    <b-modal
      id="login-modal"
      content-class="modal-radius"
      hide-footer
      centered
      scrollable
      size="md"
      v-model="showModal"
    >
      <template #modal-header="{ close }">
        <div class="d-flex align-items-center w-100">
          <p class="modal-title">Đăng nhập</p>
          <img
            src="/assets/image/icon/x-close.svg"
            class="ml-auto"
            @click="close"
            style="cursor: pointer"
          />
        </div>
      </template>
      <div>
        <b-form @submit="onSubmit">
          <b-form-group
            class="mb-3"
            id="fieldset-1"
            label="Email/số điện thoại"
            label-for="input-1"
            label-class="lable-class"
            :invalid-feedback="invalidFeedback"
            :state="state"
          >
            <b-form-input
              id="input-1"
              v-model="name"
              :state="state"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="fieldset-2"
            label="Mật khẩu"
            label-for="input-1"
            label-class="lable-class"
            :invalid-feedback="invalidFeedback"
            :state="state"
          >
            <div class="input-group mb-3">
              <b-form-input
                id="input-2"
                :type="`${showPass ? 'text' : 'password'}`"
                v-model="pass"
                :state="state"
              ></b-form-input>
              <div class="input-group-append" style="height: 40px">
                <span
                  class="input-group-text py-0"
                  style="cursor: pointer"
                  @click="showPass = !showPass"
                >
                  <i v-if="showPass" class="fas fa-eye"></i>
                  <i v-else class="fas fa-eye-slash"></i>
                </span>
              </div>
            </div>
          </b-form-group>
          <span v-if="error" class="text-danger">{{ error }}</span>
          <b-button class="w-100 login-btn" type="submit">Đăng nhập</b-button>
          <p class="attribute w-100 text-center my-2" @click="openForgetModal">
            Quên mật khẩu?
          </p>
          <p class="mention w-100 text-center mt-2 mb-3">
            Bạn chưa có tài khoản?
            <span class="attribute" @click="openCreateModal">Đăng ký</span>
          </p>
          <!-- <div class="w-100 d-flex justify-content-center align-items-center">
            <p class="border-dark w-50"></p>
            <p class="mx-3 mention text-nowrap" style="font-size: 12px">
              Hoặc tiếp tục bằng
            </p>
            <p class="border-dark w-50"></p>
          </div>
          <b-button class="w-100 btn-login-with">
            <img src="/assets/image/icon/google.svg" class="mr-2" />
            Đăng nhập với Google
          </b-button>
          <b-button class="w-100 btn-login-with">
            <img src="/assets/image/icon/fb.svg" class="mr-2" />
            Đăng nhập với Facebook
          </b-button> -->
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RepositoryFactory from "@/repository/factory";
const authenticationGuest = RepositoryFactory.get("authenticationGuest");
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isShow(to) {
      if (to === false) {
        this.$emit("close");
        this.$bvModal.hide("login-modal");
      } else {
        this.$bvModal.show("login-modal");
      }
    },
    showModal(to) {
      if (to === false) {
        this.$emit("close");
        this.$bvModal.hide("login-modal");
      } else {
        this.$bvModal.show("login-modal");
      }
    },
  },
  computed: {
    invalidFeedback() {
      return "Vui lòng nhập đủ thông tin được yêu cầu ";
    },
  },
  data() {
    return {
      state: null,
      name: null,
      pass: null,
      showModal: false,
      error: "",
      showPass: false,
    };
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      if (this.name && this.pass) {
      } else {
        this.state = false;
      }
      if (this.state === false) return;
      let body = {
        Phone: this.name,
        Password: this.pass,
      };
      let res = await authenticationGuest.LoginUser(body);
      if (res.status) {
        localStorage.setItem("server", res.data.ServerName);
        localStorage.setItem("token", res.data.Token);
        localStorage.setItem("user", JSON.stringify(res.data));
        this.$bvModal.hide("login-modal");
        this.error = "";
        location.reload();
      } else {
        this.error = res.message;
      }
    },
    openCreateModal() {
      this.$emit("create");
    },
    openForgetModal() {
      this.$emit("forget");
    },
  },
};
</script>
<style>
.modal-radius {
  border-radius: 8px !important;
}
</style>
<style>
.mention {
  color: var(--label-main-text-default, #27272a);

  /* Base/14px - Regular */
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.attribute {
  color: var(--brand-color, #c71313);
  cursor: pointer;
  /* Small/12px - Regular */
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 18px */
}
.login-btn {
  border-radius: 8px !important;
  background: var(--brand-color, #c71313) !important;
}
.lable-class {
  color: var(--label-main-text-default, #27272a);
  /* Base/14px - Bold */
  font-size: 14px !important;
  font-weight: 700;
  line-height: 150%; /* 21px */
}
</style>
<style scoped>
.btn-login-with {
  border-radius: 8px;
  border: 1px solid var(--bottom-bar-border-inactive, #ebebf0) !important;
  background: var(--alias-theme, #fff) !important;
  color: #27272a;
}
.btn-login-with:hover {
  border-radius: 8px;
  border: 1px solid var(--bottom-bar-border-inactive, #ebebf0) !important;
  background: var(--alias-theme, #fff) !important;
  color: #27272a;
}
.btn-login-with:focus {
  border-radius: 8px;
  border: 1px solid var(--bottom-bar-border-inactive, #ebebf0) !important;
  background: var(--alias-theme, #fff) !important;
  color: #27272a;
}
.border-dark {
  border-bottom: 1px solid black;
}
.modal-title {
  color: var(--brand-color, #c71313);

  /* X-Large/20px - Semi Bold */
  font-size: 20px;
  font-weight: 600;
  line-height: 150%; /* 30px */
}
</style>
